.Alert {
  padding: 20px;
  border-radius: 4px;
  background: var(--grey200);
  display: grid;
  grid-template-columns: max-content auto;
  grid-column-gap: 15px;
  color: #3B3B3B;
  align-items: center;
}
.Alert--success {
  background: var(--successColor100);
}
.Alert--info {
  background: var(--warningColor100);
}
.Alert--notice {
  background: var(--warningColor100);
}
.Alert--warning {
  background: var(--warningColor100);
}
.Alert--danger {
  background: var(--dangerColor100);
}
.Alert-icon {
  font-size: 20px;
  width: 20px;
}