.recentTransactions {}
.recentTransactions-head {
  margin-bottom: 5px;
}
.recentTransactions-mobileCard {
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;
  line-height: 2;
}
.recentTransactions-mobileCard > div:last-child {
  border-left: 1px solid var(--grey200);
  text-align: center;
}
.mobile .recentTransactions {
  display: grid;
  grid-template-rows: auto;
  grid-row-gap: 10px;
}
.mobile .recentTransactions {
  padding-bottom: 30px;
}