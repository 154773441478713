.StudentAttendanceReport {
    display: grid;
    grid-gap: 15px;
    align-items: center;
    grid-template-columns: max-content auto;
  }
  /* .StudentAttendanceReport > *:first-child {
    display: grid;
    grid-template-rows: auto;
    grid-gap: 20px;
    align-items: center;
  } */
  .desktop .StudentAttendanceReport {}
  /* .tablet .StudentAttendanceReport {
    justify-content: center;
  } */
  .mobile .StudentAttendanceReport > * {
    width: stretch;
  }
  .mobile .StudentAttendanceReport {
    grid-template-columns: auto;
  }

  .AttendanceReport-head {
    display: grid;
    grid-gap: 20px;
    padding-bottom: 30px;
  }
  .AttendanceReport {
    padding-bottom: 60px;
  }
  .AttendanceReport-loading {
    filter: blur(1px);
  }
  .desktop .AttendanceReport .AttendanceReport-head {
    grid-template-columns: 1.4fr 2fr;
    grid-template-rows: auto;
  }
  .tablet .AttendanceReport .AttendanceReport-head {
    grid-template-columns: 3fr 1fr 1fr 3fr;
    grid-template-rows: auto;
  }
  .mobile .AttendanceReport .AttendanceReport-head {
    display: block;
    padding-bottom: 0;
  }
  .mobile .AttendanceReport .AttendanceReport-head > * {
    margin-bottom: 30px;
  }
  .desktop .AttendanceReport .AttendanceReport-stats1 {
    display: grid;
    grid-template-columns: 1fr 0.8fr 1fr;
    grid-gap: 20px;
  }
  .tablet .AttendanceReport .AttendanceReport-stats1,
  .mobile .AttendanceReport .AttendanceReport-stats1 {
    grid-column: 1 / span 2;
    grid-row: 2 /span 1;
    display: grid;
    grid-template-columns: 2fr 1fr 2fr;
    grid-gap: 10px;
    text-align: center;
  }
  .AttendanceReport-stats1-cell {
    border-right: 1px solid var(--grey200);
    line-height: 30px;
  }
  .tablet .AttendanceReport .AttendanceReport-stats1-cell,
  .mobile .AttendanceReport .AttendanceReport-stats1-cell {
    padding: 0 10px;
    line-height: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .tablet .AttendanceReport .AttendanceReport-stats1-cell:last-of-type,
  .mobile .AttendanceReport .AttendanceReport-stats1-cell:last-of-type {
    border: none;
  }
  .AttendanceReport-stats2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .tablet .AttendanceReport .AttendanceReport-stats2,
  .mobile .AttendanceReport .AttendanceReport-stats2 {
    grid-column: 3 / span 2;
    grid-row: 2 /span 1;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
    justify-items: center;
  }
  .AttendanceReport-stats2-item {
    font-weight: bold;
    font-size: 13px;
    color: var(--blue);
  }
  .AttendanceReport-stats2-itemTag {
    height: 30px;
    min-width: 30px;
    border-radius: 6px;
    background: var(--grey200);
    text-align: center;
    line-height: 30px;
    color: var(--blue) !important;
  }
  .AttendanceReport-stats2-item--daysAbsent {
    color: var(--dangerColor);
  }
  .AttendanceReport-stats2-item--daysAbsent .AttendanceReport-stats2-itemTag {
    background: rgba(251,93,93, 0.35);
  }
  .AttendanceReport-stats2-item--daysLate {
    color: var(--warningColor);
  }
  .AttendanceReport-stats2-item--daysLate .AttendanceReport-stats2-itemTag {
    background: rgba(250,217,97, 0.3);
  }
  .AttendanceReport-stats2-item--daysPresent {}
  .AttendanceReport-stats2-item--daysPresent .AttendanceReport-stats2-itemTag {
    background: var(--white);
    border: 1px solid rgba(117, 175, 217, 0.3);
  }
  .AttendanceReport-stats2-item--holidays {
    color: var(--grey);
  }
  .AttendanceReport-stats2-item--holidays .AttendanceReport-stats2-itemTag {}
  .AttendanceReport-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 30px;
  }
  .tablet .AttendanceReport-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .mobile .AttendanceReport-grid {
    grid-template-columns: repeat(1, 1fr);
  }