/* .GuardianWards-ward {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: min-content auto;
    align-items: center;
}
.GuardianWards-ward > *:nth-last-child(2),
.GuardianWards-ward > *:last-child {
    grid-column: span 2;
} */

/* .wards--tablet .wards-title {
    text-align: center;
  } */
  .GuardianWards-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto;
    grid-gap: 5px;
    padding: 30px 0;
  }
  .tablet .GuardianWards .GuardianWards-grid {
    grid-template-columns: repeat(2, 1fr);
    width: 80%;
    margin: auto;
  }
  .mobile .GuardianWards .GuardianWards-grid {
    grid-template-columns: auto;
    grid-row-gap: 20px;
  }
  .GuardianWards-item {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-gap: 10px;
  }
  .GuardianWards-avi {
    margin-right: 15px;
  }