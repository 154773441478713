.studentFees {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-column-gap: 20px;
	grid-template-rows: auto;
	grid-row-gap: 5px;
}
.tablet .studentFees,
.mobile .studentFees {
	grid-template-columns: unset;
	grid-template-rows: repeat(4, auto);
	grid-row-gap: 10px;
}
.mobile .studentFees {
	padding-bottom: 30px;
}
.tablet .studentFees .studentFees-head,
.mobile .studentFees .studentFees-head {
	grid-column: unset;
}
.studentFees-head {
	grid-column: 1 / span 3;
}
.studentFees-iconContainer {
	padding: 10px;
	border-radius: 5px;
	width: 60px;
	height: 60px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.studentFees-icon {
	width: 40px;
	height: 40px;
}