.SubjectListSearch-MobileForm {
    margin-top: 20px;
}

.SubjectListSearch-TabletForm {
    display: grid;
    grid-template-columns: 250px 150px;
    align-items: center;
    grid-column-gap: 15px;
    margin-bottom: -10px;
}

.SubjectListSearch-DesktopForm {
    display: grid;
    grid-template-columns: 300px 150px;
    align-items: center;
    grid-column-gap: 15px;
}