.phoneInput {}
.react-tel-input {
  font-family: unset !important;
}
.react-tel-input input[type=tel], .react-tel-input input[type=text] {
  height: 45px !important;
  width: stretch !important;
}
.react-tel-input .flag-dropdown {
  margin: 1px !important;
  border: none !important;
  border-right: 1px solid var(--grey200) !important;
  background-color: var(--grey100) !important;
}
.react-tel-input .country-list {
  border-radius: 4px !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15) !important;
}
.react-tel-input .country-list .country:hover,
  .react-tel-input .country-list .country.highlight {
  background: var(--blue100) !important;
}
.react-tel-input .country-list .country.highlight {
  color: var(--blue) !important;
}
.react-tel-input .country-list .country.highlight .country-name {
  font-weight: bold !important;
}
.react-tel-input .selected-flag {
  z-index: 3 !important;
}