.UserSummary {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-template-rows: auto;
  grid-column-gap: 30px;
  grid-row-gap: 10px;
  padding-bottom: 20px;
  max-width: 600px;
}
.mobile .UserSummary {
  grid-column-gap: 15px;
}
.UserSummary-avatar {
  grid-row: span 2;
}
.UserSummary > *:nth-child(2){
  grid-column: span 3;
}
.UserSummary > *:nth-child(3) {
  grid-column: span 2;
}