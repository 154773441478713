.studentPerformance {}
.mobile .studentPerformance{
  padding-bottom: 30px;
}
.studentPerformance-head {
  margin-bottom: 5px;
}
.studentPerformance-legendKey {
  width: 50px;
  height: 0;
  border: #EFEFEF solid 1.5px;
  border-radius: 1.5px;
}
.studentPerformance-legendKey--dashed {
  border-width: 0.5px;
  border-style: dashed;
}
.studentPerformance-legendText {
  font-size: 12px;
  padding: 0 20px;
}
.mobile .studentPerformance .studentPerformance-legendKey {
  width: 30px;
}
.mobile .studentPerformance .studentPerformance-legendText {
  padding: 0 20px 0 10px;
  font-size: 10px;
}