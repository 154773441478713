.dashboard {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    grid-template-rows: auto;
    grid-column-gap: 20px;
    grid-row-gap: 30px;
    grid-template-areas: 
      "studentFees        studentFees         studentFees"
      "studentPerformance studentPerformance  generalStatistics"
      "absentStudents     absentStudents      absentStudents"
      "debtors            recentTransactions  recentTransactions"
      "announcements      recentTransactions  recentTransactions"
      "assessments        assessments         assessments";
  }
  .tablet .dashboard {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 
      "studentFees        generalStatistics"
      "studentPerformance studentPerformance"
      "absentStudents     absentStudents"
      "debtors            debtors"
      "recentTransactions recentTransactions"
      "announcements      announcements"
      "assessments        assessments";
  }
  .mobile .dashboard {
    display: block;
  }
  .dashboard-studentFees {
    grid-area: studentFees;
  }
  .dashboard-studentPerformance {
    grid-area: studentPerformance;
  }
  .dashboard-generalStatistics {
    grid-area: generalStatistics;
  }
  .dashboard-absentStudents {
    grid-area: absentStudents;
  }
  .dashboard-debtors {
    grid-area: debtors;
  }
  .dashboard-announcements {
    grid-area: announcements;
  }
  .dashboard-recentTransactions {
    grid-area: recentTransactions;
  }
  .dashboard-assessments {
    grid-area: assessments;
  }
  .dashboard-icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  .dashboard .table-head {
    padding-top: 0;
  }
  .dashboard .table-row {
    align-items: flex-start;
  }