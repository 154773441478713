.FormInput {
  width: stretch;
}
.FormInput--inline {
  display: flex;
  align-items: center;
}
.FormInput--inline >*:nth-child(1) {
  min-width: var(--FormInput-inline-label-minwidth);
  width: var(--FormInput-inline-label-width);
  padding-right: 10px;
}
.FormInput--inline >*:nth-child(2) {
  flex-grow: 1;
}
.FormInputControl {
  height: 45px;
  width: stretch !important;
  background: #fff;
  border: 1.3px solid hsla(0,0%,74.1%,.25) !important;
  box-shadow: 0 2px 9px hsla(0,0%,76.9%,.11);
  border-radius: 3px !important;
  color: var(--inputControlTextColor);
  transition: all .1s ease-out;
  padding: 0 10px;
  position: relative;
}
.FormInputControl-isDisabled {
  background: var(--grey100);
  cursor: not-allowed;
}
.FormInputControl-textInput {
  font-size: 14px;
  font-weight: 500;
  color: inherit;
  outline: none;
  border: none;
  min-height: inherit;
  height: inherit;
  width: 100%;
  background: transparent;
  flex-grow: 1;
}
textarea.FormInputControl-textInput {
  margin: 10px 0;
  max-height: 180px;
}
.FormInputControl-label {
  margin-bottom: 5px;
}
.FormInputControl-placeholderText,
.FormInputControl-textInput::placeholder {
  color: var(--inputControlPlaceholderColor) !important;
  font-size: 13px;
  font-weight: 500;
}
.FormInputControl-customInput {
  padding: 0 15px;
}
.FormInputControl-icon {
  width: 8px;
}
.FormInputControl--grey {
  background: rgba(189, 189, 189, 0.25);
}
.FormInputControl-isFocused {
  border-color: var(--blue) !important;
  color: var(--blue) !important;
}
.FormInputControl-isError {
  border-color: var(--dangerColor) !important;
  color: var(--dangerColor) !important;
}
.FormInputControl-isSuccess {
  border-color: var(--successColor) !important;
  color: var(--successColor) !important;
}
.FormInputControl--multiline {
  height: auto;
  min-height: 40px;
  max-height: 250px;
}
.FormInputControl-prepend {
  height: 100%;
  background: var(--grey100);
  padding: 0 10px;
  margin: 0 10px 0 -10px;
  border-right: 1px solid var(--grey200);
  color: var(--inputControlTextColor);
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
}