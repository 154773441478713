.Settings .NoDataAvailable {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.mobile .Settings .NoDataAvailable,
.tablet .Settings .NoDataAvailable {
  padding: 16px;
  justify-content: flex-start;
  margin-top: 38px;
  text-align: center;
}

.Settings .NoDataAvailable--Title {
  color: #6d6d6d;
  font-size: 20px;
}

.mobile .Settings .NoDataAvailable--Title,
.tablet .Settings .NoDataAvailable--Title {
  font-size: 15px;
}

.Settings .NoDataAvailable--Logo {
  width: 214px;
  height: 162px;
  margin: 58px 0;
}

.mobile .Settings .NoDataAvailable--Logo,
.tablet .Settings .NoDataAvailable--Logo {
  width: 87px;
  height: 66px;
  margin: 37px 0;
}

.mobile .Settings .NoDataAvailable--Description,
.tablet .Settings .NoDataAvailable--Description {
    width: 100%;
    margin-bottom: 61px;
}

.Settings .NoDataAvailable button {
    padding: 0 80px;
}

.mobile .Settings .NoDataAvailable button,
.tablet .Settings .NoDataAvailable button {
    padding: 0 70px;
}
