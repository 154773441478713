.schoolSwapMenu {
  position: fixed;
  width: calc(var(--sidebar-width) - 30px);
  max-height: 90vh;
  z-index: 10;
  top: 0;
  left: 0;
  background-color: #fff;
  border: 1px solid hsla(0,0%,74.1%,.25);
  box-shadow: 0 7.08823px 35.4412px rgba(0,0,0,.07);
  border-radius: 7.08823px;
  overflow: auto;
  margin: 5px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  transition: var(--fadeTransition);
  box-sizing: unset;
}
.schoolSwapMenu-isHidden {
  opacity: 0;
  pointer-events: none;
}
.schoolSwapMenu-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}
.schoolSwapMenu-logo {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}
.schoolSwapMenu-title {
  font-weight: 600;
  font-size: 12px;
}
.schoolSwapMenu-closeButton {
  width: 10px;
  height: 10px;
  padding: 5px;
  box-sizing: unset;
}
.schoolSwapMenu-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-radius: 5px;
  font-size: 13px;
  font-weight: 600;
  margin: 2.5px 0;
}
.schoolSwapMenu-item img { padding: 0 5px }
.schoolSwapMenu-item-isActive {
  background: var(--blue);
  color: white;
}
.schoolSwapMenu-item--secondary {
  color: var(--blue);
}
/* .schoolSwapMenu-divider {
  border-top: 1px solid var(--grey200);
  margin: 5px 0;
} */
.mobile .schoolSwapMenu {
  margin: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border: none;
  width: stretch;
}
.mobile .schoolSwapMenu .schoolSwapMenu-closeButton {
  transform: scale(1.7);
}
.mobile .schoolSwapMenu .schoolSwapMenu-item {
  padding-top: 15px;
  padding-bottom: 15px;
}