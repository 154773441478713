.ant-switch {
  font-family: unset !important; 
  height: 30px !important;
  width: 65px !important;
  border-color: var(--grey200) !important;
  background-color: white !important;
  margin: 5px 0;
}
.ant-switch-checked {
  background-color: var(--blue) !important;
  border-color: var(--blue) !important;
}
.ant-click-animating-node {
  border: 0 solid var(--blue) !important;
}
.ant-switch:after {
  font-family: "Material Icons" !important;
  width: 25px !important;
  height: 25px !important;
  border-radius: 25px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--blue) !important;
  color: white !important;
  content: 'close' !important;
  font-size: 20px !important;
}
.ant-switch-checked:after {
  content: 'check' !important;
  color: var(--blue) !important;
  background-color: #FFF !important;
}
.ant-switch-inner {
  color: black !important;
}
.ant-switch-checked .ant-switch-inner {
  color: #FFF !important;
}