.Settings .AcademicSessions {
    width: 100%;
    padding-top: 10px;
}

.mobile .Settings .AcademicSessions,
.tablet .Settings .AcademicSessions {
    padding: 29px 20px;
}

.Settings .AcademicSessions--Body {
    width: 100%;
    height: calc(100vh - 300px);
}

.Settings .AcademicSessions--Header {
    width: 100%;
}

.Settings .AcademicSessions--Header h2 {
    color: #6D6D6D;
}

.Settings .AcademicSessions--cta-wrapper {
    display: flex;
    margin-bottom: 50px;
    margin-top: 26px;
}

.mobile .Settings .AcademicSessions--cta-wrapper,
.tablet .Settings .AcademicSessions--cta-wrapper {
    justify-content: center;
    margin-top: 26px;
}

.Settings .AcademicSessions--cta-wrapper button:first-child {
    margin-right: 20px;
}

.Settings .AcademicSessions--cta-wrapper button {
    padding: 0 24px;
}

.mobile .Settings .AcademicSessions--cta-wrapper button,
.tablet .Settings .AcademicSessions--cta-wrapper button {
    padding: 0 50px;
}

.Settings .AcademicSessions--cta-table-wrapper th,
.Settings .AcademicSessions--cta-table-wrapper td  {
    text-align: center;
}

.Settings .MobileRow {
    display: flex;
    padding-left: 30px;
    justify-content: space-between;
}

.Settings .MobileRow--Row {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.Settings .MobileRow--Row span:first-child {
    margin-bottom: 7px;
}

.Settings .MobileRow--cta img {
    padding: 8px;
}

.Settings .NoAcademicSessions button {
    padding: 0 80px;
}

.mobile .Settings .NoAcademicSessions button,
.tablet .Settings .NoAcademicSessions button {
    padding: 0 70px;
}
