@import '../styles/variables.css';
@import '../styles/flex.css';
@import '../styles/global.css';
@import '../styles/keyframes.css';
@import '../styles/overrides.css';
@import '../styles/text.css';
@import '../styles/touchable.css';
@import '../styles/utils.css';
@import '../styles/forminput.css';
@import '../styles/divider.css';
.Wrapper {
    display: grid;
    grid-template-columns: var(--sidebar-width) auto;
    grid-template-rows: var(--schoolSwap-height) auto;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
}

.Wrapper-content {
    grid-row: 1 / span 2;
    grid-column: 2;
    background: var(--grey100);
    padding: 20px 30px;
    overflow: auto;
    min-height: stretch;
}

.Wrapper-isDisabled:not(.Wrapper--hide) {
    overflow: hidden;
}

.tablet .Wrapper, .mobile .Wrapper {
    display: block;
}

.tablet .Wrapper .Wrapper-content, .mobile .Wrapper .Wrapper-content {
    padding: 10px;
    overflow-y: visible;
    overflow-x: hidden;
}

.WrapperToolbar {
    display: grid;
    grid-template-columns: 2.5fr max-content;
    grid-column-gap: 30px;
    padding-bottom: 30px;
}

.WrapperToolbar-searchContainer {
    display: grid;
    grid-template-columns: 30px auto;
    align-items: center;
    /* height: auto !important; */
}

.WrapperToolbar-searchIcon {}

.WrapperToolbar-searchInput {}

.tablet .WrapperToolbar, .mobile .WrapperToolbar {
    display: none;
}

/* .Wrapper--hide {
    display: block;
}

.Wrapper--hide>*:not(.Wrapper-content) {
    display: none;
}

.Wrapper--hide .Wrapper-content {
    padding: 0;
} */

.Wrapper-isModalVisible {
    filter: blur(2px);
    pointer-events: none;
}
