.batchSummary {
    display: flex;
    justify-content: flex-start;
  }
  .mobile .batchSummary {
    display: block;
  }
  /* .batchSummary--mobile {
    flex-direction: column;
  }
  .batchSummary--tablet {
    display: grid;
    grid-template-columns: 60% 40%;
    grid-column-gap: 30px;
  } */
  /* .batchSummary--tablet > *:last-child {
    grid-row: 1;
    grid-column: 2;
  } */
  .batchSummary-group {
    display: grid;
    grid-template-columns: max-content repeat(2, auto);
    grid-template-rows: auto;
    grid-column-gap: 30px;
    grid-row-gap: 10px;
    padding-bottom: 20px;
    border-right: 1px solid var(--grey200);
    padding-right: 15px;
    flex: 1;
  }
  .mobile .batchSummary-group{
    border-right: none;
  }
  .desktop .batchDetails{
    display: flex;
    flex: 1;
  }
/* 
  .mobile .batchSummary-group {
    border-right: none;
    padding-right: 0;
  } */
  .batchSummary-avatar {
    grid-row: span 2;
  }
  .batchSummary-group > *:nth-child(2) {
    grid-column: span 2;
  }