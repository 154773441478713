.GuardianList {
    display: grid;
    grid-gap: 15px;
}
.mobile .GuardianListItem {
    display: grid;
    grid-template-rows: auto;
    grid-gap: 15px;
}
.mobile .GuardianListItem-wards {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: 15px;
}
.GuardianListItem-wardItems {
    display: grid;
    grid-row: auto;
    grid-gap: 15px;
}
.GuardianListItem-wardItems > * {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-gap: 10px;
}