.flex{
  display: flex;
}
.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.flex-row--reverse {
  flex-direction: row-reverse;
}
.flex-col {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.flex-col.inline, .flex-row.inline {
  display: inline-flex;
}
.flex-spaceBetween {
  justify-content: space-between;
}
.flex-alignCenter {
  align-items: center;
}
.flex-alignStart {
  align-items: flex-start;
}
.flex-alignEnd {
  align-items: flex-end;
}
.flex-alignStretch {
  align-items: stretch;
}
.flex-alignSelfEnd {
  align-self: flex-end;
}
.flex-justifyCenter {
  justify-content: center;
}
.flex-justifyEnd {
  justify-content: flex-end !important;
}
.flex-justifySelfEnd {
  justify-self: flex-end;
}
.flex-alignSelfCenter {
  align-self: center;
}
.flex-wrap {
    flex-wrap: wrap;
}
