.mobile .TransactionForm,
.tablet .TransactionForm {
    margin-bottom: 100px;
}
.AddStudent-studentInfo {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 30px;
}
.tablet .AddStudent-studentInfo {
    grid-template-columns: repeat(2, 1fr);
}
.mobile .AddStudent-studentInfo {
    grid-template-columns: repeat(1, 1fr);
}

.desktop .AddTransaction-saveButton {
    margin: 20px auto;
    width: 50%;
}
.tablet .AddTransaction-saveButton,
.mobile .AddTransaction-saveButton {
    background: white;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 15px;
    z-index: 3;
    box-shadow: 1px -1px 10px rgba(217, 228, 236, 0.22);
}
.tablet .AddTransaction-saveButton {
    padding-left: 30%;
    padding-right: 30%;
}