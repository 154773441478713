.FinanceTransactionSearch-TabletForm {
    display: grid;
    grid-template-columns: 2fr 2fr repeat(2, max-content);
    align-items: center;
    grid-column-gap: 15px;
    margin-bottom: -10px;
}
.FinanceTransactionSearch-DesktopForm {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr max-content;
    align-items: center;
    grid-column-gap: 15px;
}