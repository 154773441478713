.Settings .SubjectNames {
    width: 100%;
    padding-top: 10px;
}

.mobile .Settings .SubjectNames,
.tablet .Settings .SubjectNames {
    padding: 29px 20px;
}

.Settings .SubjectNames--Body {
    width: 100%;
    height: calc(100vh - 300px);
}

.Settings .SubjectNames--Header {
    width: 100%;
}

.Settings .SubjectNames--Header h2 {
    color: #6d6d6d;
}

.Settings .SubjectNames--cta-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
    margin-top: 26px;
}

.mobile .Settings .SubjectNames--cta-wrapper,
.tablet .Settings .SubjectNames--cta-wrapper {
    justify-content: center;
    margin-bottom: 30px;
    margin-top: 26px;
}

.Settings .SubjectNames--cta-wrapper button:first-child {
    margin-right: 20px;
}

.mobile .Settings .SubjectNames--cta-wrapper button:first-child,
.tablet .Settings .SubjectNames--cta-wrapper button:first-child {
    margin-right: 0px;
}

.Settings .SubjectNames--cta-wrapper button {
    padding: 0 24px;
}

.mobile .Settings .SubjectNames--cta-wrapper button,
.tablet .Settings .SubjectNames--cta-wrapper button {
    width: 100%;
}

.Settings .SubjectNames--cta-table-wrapper .Table th,
.Settings .SubjectNames--cta-table-wrapper .Table td {
    padding: 15px 15px 15px 90px;
}

.Settings .SubjectNames-MobileRow {
    position: relative;
}

.Settings .SubjectNames-MobileRow--cta {
    position: absolute;
    right: 0px;
    top: -8px;
}

.Settings .SubjectNames-MobileRow--Body {
    display: flex;
    flex-direction: column;
    padding: 20px 10px 10px;
}

.Settings .SubjectNames-MobileRow--Body--top {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 20px;
}

.Settings .SubjectNames-MobileRow--Row span:first-child {
    margin-right: 20px;
}
