.DateRangeInput-popover {
  padding: 10px 30px !important;
}
.DateRangeInput-popover .ant-popover-inner-content {
  max-height: unset;
}
.DateRangeInput-prepend {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  background: var(--grey100);
  border-right: 1px solid var(--grey200);
  border-top-left-radius: 3px !important;
  border-bottom-left-radius: 3px !important;
  height: stretch;
}
.DateRangeInput-prepend img {
  width: 15px;
}
.DateRangeInput-modal .ant-modal-body {
  padding: 0;
}