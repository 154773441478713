.ant-popover {
  font-family: unset !important;
  font-size: 14px;
}
.override-popover .ant-popover-arrow {
  display: none;
}
.ant-popover-inner-content {
  background: #ffffff;
  border: 0.891296px solid rgba(189, 189, 189, 0.25);
  box-shadow: 0px 10.6851px 53.4256px rgba(0, 0, 0, 0.07);
  border-radius: 2.67389px;
  padding: 10px;
  max-height: 300px;
  overflow: auto;
}
.ant-radio-checked .ant-radio-inner:after,
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--blue) !important;
}
.ant-checkbox-checked .ant-checkbox-inner,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-radio-checked .ant-radio-inner,
.ant-radio-wrapper:hover .ant-radio-inner {
  border-color: var(--blue) !important;
}
.ant-radio,
.ant-checkbox {
  transform: scale(1.3);
}
.ant-checkbox-group,
.ant-checkbox-wrapper,
.ant-radio-group,
.ant-radio-wrapper {
  font-family: unset !important;
}
.table-row .ant-checkbox-wrapper,
.table-head .ant-checkbox-wrapper {
  align-items: center;
  display: flex;
}
.ant-dropdown {
  font-family: unset !important;
}
.ant-dropdown-menu-item:hover {
  background: var(--blue100);
}
.ant-dropdown-menu-item-selected {
  background: var(--blue100);
  color: var(--blue);
  font-weight: bold;
}
.ant-dropdown-menu {
  max-height: 50vh;
  overflow: auto;
}
.ant-tooltip {
  font-family: unset !important;
  min-width: 170px;
}
.ant-tooltip-inner {
  background-color: white !important;
  color: unset !important;
}
.ant-tooltip-arrow {
  /* border-color: white !important; */
  filter: invert(100%);
}
/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
textarea,
textarea:hover
textarea:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #FFF inset;
  height: 90%;
}
.dateInput-overlay {
  z-index: 10;
}
button[type="button"]:not([role="switch"]):not(.Button):not(.ant-btn):not(.fc-button):not(.react-datepicker__navigation) {
  background: transparent;
  border: none;
}