.EmployeePrivileges {}
.mobile .EmployeePrivileges-buttonContainer {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: white;
    padding: 10px;
    left: 0;
    right: 0;
    box-shadow: 1px -1px 10px rgba(217, 228, 236, 0.22);
    z-index: 5;
}
.EmployeePrivileges-buttonContainer {
    margin-top: 30px;
    display: flex;
    justify-content: center;
}
.desktop .EmployeePrivileges-grid {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-template-rows: max-content;
    grid-column-gap: 20px;
    grid-row-gap: 40px;
    align-items: flex-start;
}
.mobile .EmployeePrivileges-grid {
    margin-bottom: 90px;
}
.mobile .EmployeePrivileges-grid > *+* {
    margin-top: 15px;
}
.EmployeePrivileges-grid > *:first-child,
.EmployeePrivileges-grid > *:nth-child(2) {
    grid-row: span 2;
}
.EmployeePrivileges-group {
    border-top: 1px solid var(--grey100);
}
.EmployeePrivileges-groupItem:first-of-type {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 10px;
    letter-spacing: 1px;
}
.EmployeePrivileges-groupItem {
    display: grid;
    grid-template-columns: max-content auto;
    align-items: center;
    grid-column-gap: 10px;
    border-bottom: 1px solid var(--grey100);
}
.EmployeePrivileges-groupItem:nth-of-type(even) {
    background: var(--blue100);
}
.EmployeePrivileges-groupItem > *:first-child {
    border-right: 1px solid var(--grey100);
    padding: 10px;
}