.Modal .ant-modal,
.Modal-smallSize .ant-modal {
  font-family: unset !important;
}
.Modal .ant-modal-header,
.Modal-smallSize .ant-modal-header {
  border: none !important;
  text-align: center;
}
.desktop.Modal .ant-modal {
  width: 900px !important;
}
.tablet.Modal .ant-modal {
  width: 80% !important;
}