.AssignRole {
    margin-bottom: 25px;
}

.AssignRole-input {
    display: grid;
    grid-template-columns: 4fr 1fr;
    margin-top: 12px;
}

.mobile .AssignRole-input {
    grid-template-columns: 6fr 1fr;
}

.AssignRole-input>div:nth-of-type(1) {
    position: relative;
    padding-left: 22.5px;
}

.AssignRole-input>div:nth-of-type(2) {
    text-align: center;
    padding: 12px 0;
}

.AssignRole-input>div:nth-of-type(2) img {
    height: 21px;
    cursor: pointer;
}

.Avatar.AssignRole-input-image {
    width: 45px;
    height: 45px;
    position: absolute;
    z-index: 5;
    left: 0;
    top: 0;
}

.AssignRole-input--select {
    padding-left: 35px;
}

.AssignRole-input--select.FormInputControl-isDisabled {
    background: initial;
}

.AssignRole-delete-dialog {
    z-index: 11;
}