.assignSelectedStudents>p {
    text-align: center;
    font-size: 14px;
    margin-bottom: 23px;
}

.assignSelectedStudents-updateButton {
    margin: 48px auto 18px auto;
    padding: 10px 36px;
}

.assignSelectedStudents-item {
    display: grid;
    grid-template-columns: 40px 1fr;
    grid-column-gap: 15px;
    grid-row-gap: 14px;
}

.assignSelectedStudents-items {
    margin-top: 35px;
}

.assignSelectedStudents-items .Card:not(:last-child) {
    margin-bottom: 13px;
}

.assignSelectedStudents-item>div:nth-of-type(2) {
    grid-column: 2;
    font-size: 12px;
}

.assignSelectedStudents-item>div:nth-of-type(2)>div:nth-of-type(1) {
    font-size: 10px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 5px;
}

.assignSelectedStudents-item>div:nth-of-type(1) {
    font-size: 14px;
}