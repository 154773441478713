.StudentGuardianRelations {
    display: grid;
    grid-gap: 30px;
}
.tablet .StudentGuardianRelations {
    width: 85%;
    margin: auto;
}
.tablet .StudentGuardianRelations > button,
.mobile .StudentGuardianRelations > button {
    order: 2;
    margin: auto;
}
.StudentGuardianRelations-grid {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 5px;
    overflow: hidden;
}
.tablet .StudentGuardianRelations-grid {
    grid-template-columns: repeat(3, 1fr);
}
.mobile .StudentGuardianRelations-grid {
    grid-template-columns: 1fr;
}
.StudentGuardianRelationsItem {
    display: grid;
    grid-template-columns: 40px 1fr;
    grid-gap: 15px;
    grid-template-rows: auto;
    overflow: hidden;
}
.StudentGuardianRelationsItem > *:not(:nth-child(1)):not(:nth-child(2)) {
    grid-column: span 2;
}
.StudentGuardianRelationsItem > *:last-child {
    align-items: flex-end;
}
.StudentGuardianRelationsItem-wards > *+* {
    margin-left: -10px;
}