.EmployeeBioData {
    display: grid;
    grid-template-columns: 1.2fr 1fr;
    grid-template-rows: max-content;
    grid-gap: 10px;
}
.desktop .EmployeeBioData > *:first-child,
.tablet .EmployeeBioData > *:first-child {
    grid-row: span 4;
}
.tablet .EmployeeBioData {
    grid-template-columns: repeat(2, 1fr);
}
.mobile .EmployeeBioData {
    grid-template-columns: unset;
}
.EmployeeBioData > *:first-child {
    grid-row: span 2;
}