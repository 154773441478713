.pos {
    max-width: 320px;
    height: auto;
    margin: 0 auto;
    background: #fff
}
.pos-header{
    display: grid;
    grid-template-columns: minmax(15mm, max-content) 2fr;
    grid-column-gap: 5px;
    padding-top: 19px;
}
.pos-header div:nth-child(2) > * {
    margin-bottom: 8px;
}
.pos-header-logo {
    justify-self: center;
}
.pos-cardHeader{
    padding: 15px;
    font-size: 10px;
}
.pos-card{
    border: 1.5px dashed rgba(189, 189, 189, 0.25);
    margin: 12px;
    padding-bottom: 0;
}
.pos-walletTransactions{
    background: rgba(238, 245, 250, 0.43);
}
.pos-total {
    background-color: var(--blue);
    color: #ffffff;
}
.FeeTransactionsItem>*>*>*{
    margin-bottom: 5px;
}
.FeeTransactionsItem>*>* {
    margin-bottom: 1rem;
}