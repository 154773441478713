.assessments {}
.mobile .assessments {
  display: grid;
  grid-template-rows: auto;
  grid-row-gap: 10px;
  padding-bottom: 30px;
}
.assessments-head {
  margin-bottom: 5px;
}
.assessments-mobileCard {
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;
  line-height: 2;
}
.assessments-mobileCard > div:last-child {
  border-left: 1px solid var(--grey200);
  text-align: center;
}
