:root {
  --avi-default-size: 80px;
  --avi-small-size: 40px;
  --avi-xsmall-size: 25px;
}
.Avatar {
  width: var(--avi-default-size);
  height: var(--avi-default-size);
  border-radius: var(--avi-default-size);
  overflow: hidden;
  box-sizing: unset;
  background-color: var(--grey200);
}
.Avatar > * {
  height: 100%;
  width: 100%;
  border-radius: inherit;
  background: var(--grey200);
}
.Avatar--small {
  width: var(--avi-small-size);
  height: var(--avi-small-size);
  border-radius: var(--avi-small-size);
}
.Avatar--xsmall {
  width: var(--avi-xsmall-size);
  height: var(--avi-xsmall-size);
  border-radius: var(--avi-xsmall-size);
}
.Avatar--isBordered {
  padding: 5px;
  background: var(--blueGradient);
}
.Avatar--small.Avatar--isBordered,
.Avatar--xsmall.Avatar--isBordered {
  padding: 2px;
}