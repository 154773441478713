.StudentFeesItem {
    margin-bottom: 10px;
    display: grid;
    grid-gap: 10px;
    grid-template-rows: auto;
}

.StudentFeesItem  div:first-child,
.StudentFeesItem  div:nth-child(2){
    grid-column-start: 1;
    grid-column-end: 3;
}

.tablet .StudentFeesItem {
    grid-template-columns: 1fr 1.4fr 1fr 1fr 1fr min-content;
}

.tablet .StudentFeesItem  div:nth-child(2){
    grid-column-start: 1;
    grid-column-end: 7;
}
.mobile .StudentFeesItem-checkbox {
    grid-row: 1 / span 2;
    grid-column: 3;
}
.tablet .StudentFeesItem-checkbox {
    grid-column: 6;
    grid-row: 1;
}
.mobile .StudentFeesItem-buttonGroup {
    grid-row: 3;
    grid-column: 2 / span 2;
}
.tablet .StudentFeesItem-buttonGroup {
    grid-column: span 2;
}
.StudentFeesItem-buttonGroup > * {
    align-self: stretch;
}
.StudentFeesItem-checkbox,
.mobile .StudentFeesItem-buttonGroup {
    justify-self: flex-end;
}
.tablet .StudentFeesItem-menu {
    grid-column-end: 7;
}
.tablet .StudentFinanceSummary {
    display: flex;
    justify-content: inherit;
}
.mobile .StudentFinanceSummary-detail {
    padding-top: 10px;
}
.StudentTransactionsItem {
    margin-bottom: 10px;
    display: grid;
    grid-row-gap: 10px;
    grid-column-gap: 30px;
    grid-template-rows: auto;
    grid-template-columns: auto max-content;
}
.StudentFees-header {
    display: grid;
    grid-template-columns: auto max-content;
}
.mobile .StudentFees-header {
    grid-template-columns: auto;
}
.StudentFees-header-left {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}
.StudentFees-header-right {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.mobile .StudentFees-header-right {
    grid-row: 1;
}

.StudentFees-header-right > *:first-child {
    margin-right: 5px;
}
.mobile .StudentFees-header-right > * {
    width: stretch;
}

.tablet .printBtn {
    position: absolute;
    left: 85%;
    top: 75%;
}
.mobile .printBtn {
    position: absolute;
    left: 75%;
    top: 70%;
}
