.StudentSummary {
    display: grid;
    grid-template-columns: 1.8fr 2fr 1fr;
    grid-column-gap: 20px;
}

.tablet .StudentSummary {
    grid-template-columns: 1.8fr 2fr;
    position: relative;
}

.tablet .StudentSummary>*:nth-last-child(2) {
    grid-column: 2;
}

.tablet .StudentSummary>*:last-child {
    grid-column: 2;
    position: absolute;
    bottom: 0;
    right: 0;
}

.mobile .StudentSummary {
    display: block;
}

.StudentSummary-cell1, .StudentSummary-cell2, .StudentSummary-cell3 {
    padding-bottom: 20px;
    height: max-content;
}

.StudentSummary-cell1 {
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-template-rows: auto;
    grid-column-gap: 15px;
}

.StudentSummary-cell1>*:nth-child(2) {
    grid-column: span 2;
}

.StudentSummary-cell2, .StudentSummary-cell3 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    grid-gap: 10px;
    border-left: 1px solid var(--grey300);
    padding-left: 20px;
}

.StudentSummary-cell2>*:first-child, .StudentSummary-cell3>*:first-child {
    grid-column: span 2;
}

.mobile .StudentSummary .StudentSummary-cell2, .mobile .StudentSummary .StudentSummary-cell3 {
    border-left: none;
    padding: 5px;
}

.StudentSummary-avatar {
    grid-row: span 2;
}