.text-small {
  font-weight: 400;
  font-size: 10px !important;
}
a.white-link{
    color: white;
}
a.white-link:hover{
    color: var(--blue);
}
.text-p {
  font-size: 13px !important;
}
.text-large {
  font-size: 16px !important;
}
.text-h1 {
  font-size: 30px !important;
  font-weight: 700;
}
.text-h2 {
  font-size: 22px !important;
  font-weight: 600;
}
.text-h3 {
  font-size: 18px !important;
  font-weight: 400;
}
.text-h4 {
  font-size: 15px !important;
  font-weight: 600;
}
.text-h1,
.text-h2,
.text-h3,
.text-h4 {
  line-height: 1.75;
}
.text--grey {
  color: var(--grey);
}
.text--blue {
    color: var(--blue);
}
.text--primary,
.text--primary:hover {
  color: var(--blue);
}
.text--info,
.text--info:hover {
  color: var(--infoColor);
}
.text--success {
  color: var(--successColor);
}
.text--danger {
  color: var(--dangerColor);
}
.text--uppercase {
  text-transform: uppercase;
}
.text--bold {
  font-weight: 700;
}
.text--italic {
  font-style: italic;
}
.text--alignCenter {
  text-align: center;
}
.text--center {
  text-align: -webkit-center;
  text-align: center;
}
.text--right {
  text-align: right;
}
.text--spacedLetters {
  letter-spacing: 1.8px;
}
.text--noWrap {
  white-space: nowrap;
}
/* .text--ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
} */

ul.listStyle--none li {
    list-style: none;
}

@supports (-webkit-line-clamp: 2) {
  .text-clamp2lines {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
.text--ellipsis {
  text-overflow: ellipsis;

  /* Required for text-overflow to do anything */
  white-space: nowrap;
  overflow: hidden;
}