.desktop .studentBehaviour-card,
.tablet .studentBehaviour-card {
    width: 49%;
}
.mobile .studentBehaviour-card {
    width: 100%;
}

.students-behaviourList{
    border-bottom: 1px solid rgba(189, 189, 189, 0.25);
    display: flex;
    justify-content: space-between;
}