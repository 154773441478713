.SubjectAssessment {
    display: grid;
    grid-gap: 15px;
}

.mobile .SubjectAssessment {
    padding: 10px 0;
}

.mobile .SubjectAssessmentItem {
    position: relative;
    display: grid;
    grid-template-columns: repeat(3, auto);
    font-size: 16px;
    align-items: center;
    grid-row-gap: 15px;
}

.mobile .SubjectAssessmentItem>div>span {
    font-size: 10px;
    color: var(--inputControlTextColor);
    display: block
}

.mobile .SubjectAssessmentItem div.assCatBlock {
    grid-column: span 2;
}

.mobile .SubjectAssessmentItem>div:nth-child(1) {
    grid-column: 1 /3;
}

.mobile .SubjectAssessmentItem>div:nth-child(1)>span {
    font-weight: normal;
}

.mobile .SubjectAssessmentItem>div:nth-child(2) {
    text-align: end;
    padding-right: 10px;
}

.SubjectAssessment-popover>div {
    cursor: pointer;
    font-size: 12px;
    line-height: 19px;
    position: relative;
}


.SubjectAssessment-popover--more>div {
    padding: 10px;
}

.SubjectAssessment-popover>div:not(:last-child) {
    border-bottom: 1px solid rgba(189, 189, 189, 0.25);
}

.SubjectAssessment-more {
    display: inline-block;
    padding: 0 12px;
}

.SubjectAssessment-more::after,
.SubjectAssessment-more:hover::after {
    background: transparent;
}

.mobile .SubjectAssessment-more {
    padding: 0 0 0 12px;
}

.SubjectAssessment .Table table tbody tr td:nth-of-type(9) {
    text-align: right;
}