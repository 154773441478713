.generalStatistics {
    display: grid;
    grid-template-rows: auto repeat(3, 1fr);
    grid-row-gap: 5px;
  }
  .tablet .generalStatistics {
    grid-row-gap: 10px;
  }
  .mobile .generalStatistics {
    padding-bottom: 30px;
  }