.photoInput {
  border: 1px dashed var(--grey);
  padding: 10px;
  height: inherit;
  width: inherit;
  margin-bottom: 15px;
}
.photoInput--focus {
  border-color: var(--blue);
}
.photoInput--success {
  border-color: var(--successColor);
}
.photoInput--error {
  border-color: var(--dangerColor);
}
.photoInput--display {
  height: 200px;
  width: 90%;
  background-color: #f3f3f3;
  border: none;
  border-radius: 5px;
}
.display-text {
  color: #2e2e2e;
  font-weight: 400;
  margin-top: 22px;
}
.photoInput--display img {
  width: 66px !important;
  height: 55px !important;
}
