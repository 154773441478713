.tablet .financeItem,
.mobile .financeItem {
  display: grid;
  margin-bottom: 12px;
}


/**  DESKTOP **/
.desktop .financeItem {
    border-bottom: 1px solid var(--grey100);
    padding-bottom: 30px;
    margin-bottom: 30px;
}
.desktop .financeItem:last-of-type {
    border: none;
    margin-bottom: 0;
}
.desktop .financeItem .financeItem-summary {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 15px 15px;
}
.desktop .financeItem .financeItem-summary > * {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    align-items: center;
}
.desktop .financeItem .financeItem-summary > *:first-child {
    grid-column-gap: 15px;
}
.desktop .financeItem .financeItem-summary > *:nth-child(2) {
    grid-column-gap: 60px;
    margin-right: 10rem
}
.desktop .financeItem .table-row {
    grid-template-columns: 10px repeat(2, 1fr) repeat(3, 0.6fr) 1fr 10px;
    grid-gap: 20px;
    padding-left: 15px;
    padding-right: 15px;
}
.desktop .financeItem .table-row:not(.table-head):nth-child(even) {
    background: var(--blue100);
}




/* MOBILE */
.mobile .financeItem .financeItem-summary {
  display: grid;
  padding: 10px;
}
.mobile .financeItem .financeItem-summary > .studentDetails {
  grid-column: span 5;
  display: grid;
  grid-template-columns: 40px 1fr;
  grid-column-gap: 15px;
  align-items: center;
  padding-bottom: 5px;
}
.mobile .financeItem .financeItem-summary > .studentDetails img {
  grid-row: span 2;
}

.mobile .financeItem .financeItem-summary > .studentFinanceSummary {
    grid-row: 2;
    grid-column: span 5;
  grid-template-columns: max-content;
  margin-left: 20px;
  padding-left: 35px;
  border-left: 1.5px dashed #BDBDBD;
  padding-bottom: 5px;
}

.mobile .financeItem .financeItem-summary .walletBalance {  
  display: grid;
}
.mobile .financeItem .financeItem-summary .walletBalance span:first-child,
.mobile .financeItem .financeItem-summary .dueFees span:first-child{
  font-size: 11px;
}
.mobile .financeItem .financeItem-summary .dueFees{
  display: grid;
}
.mobile .financeItem .financeItem-summary > .seeMoreButton{
  grid-column: 1 /2;
  align-self: center;
  padding-left: 7px;
}
.mobile .financeItem .financeItem-summary > .payButton {
  display: flex;
  align-items: center;
  justify-self: flex-end;
  grid-column: 3 /6;
  text-align: end;
}
.mobile .financeItem .financeItem-summary > .printButtonnnn {
  grid-column-start: 4;
  grid-column-end: 5;
  grid-row: 1;
  align-self: flex-start;
  justify-self: flex-end;
  padding: 5px 10px;
}
.mobile .financeItem .financeItem-summary > *:last-child {
  grid-column: 1 / 6;
  margin-left: 20px;
  padding-left: 35px;
  border-left: 1.5px dashed #BDBDBD;
  grid-row-start: 3;
}

.mobile .financeItem .financeItem-card {
  display: grid;
  grid-gap: 15px;
  padding-left: 0;
  border-top: 2px solid rgba(189, 189, 189, 0.25);
    border-bottom: unset;
    border-right: unset;
    border-left: unset;
    border-radius: unset;
}
.mobile .financeItem .financeItem-card:last-child {
padding-bottom: 35px;
}


.mobile .financeItem .financeItem-card > *:first-child {
  grid-column: 3;
  grid-row: 1 / span 2;
  justify-self: flex-end;
  align-self: flex-start;
}
.mobile .financeItem .financeItem-card > *:nth-child(2){
  display: flex;
}
.mobile .financeItem .financeItem-card > *:nth-child(4) div:first-child,
.mobile .financeItem .financeItem-card > *:nth-child(5) div:first-child{
  text-transform: uppercase;
  font-size: 10px;
  font-weight: bold;
}

.mobile .financeItem .financeItem-card > *:nth-child(2), 
.mobile .financeItem .financeItem-card > *:nth-child(3){
 grid-column-start: 1;
 grid-column-end: 3;
}

/* TABLET */
.tablet .financeItem .financeItem-summary {
  display: grid;
  grid-template-columns: repeat(4, max-content);
  justify-content: space-between;
  align-items: flex-start
}
.tablet .financeItem .financeItem-summary > .studentDetails {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-column-gap: 15px;
  align-items: center;
}
.tablet .financeItem .financeItem-summary > .studentDetails img {
  grid-row: span 2;
}
.tablet .financeItem .financeItem-summary > .studentDetails div:nth-child(3) {
  margin-left: 20px;
  padding: 10px;
  border-left: 1.5px dashed #BDBDBD;
}

.tablet .financeItem .financeItem-summary .walletBalance{
  display: inline-flex;
  padding: 0 8px;
}


.tablet .financeItem .financeItem-summary > .seeMoreButton{
  grid-row-start: 4;
  padding-left: 7px;
  align-self: center;
}
.tablet .financeItem .financeItem-summary > .payButton {
  display: inline-flex;
  align-items: center;
  grid-row-start: 4;
  grid-column-start: 3;
  grid-column-end: 6;
  justify-content: flex-end;
}
.tablet .financeItem .financeItem-summary > *:last-child {
  grid-column: 1 / 6;
  margin-left: 20px;
  padding-left: 25px;
  border-left: 1.5px dashed #BDBDBD;
 }
.tablet .financeItem .financeItem-card {
  display: grid;
  grid-gap: 15px;
  padding: 15px 10px;
  align-items: center;
  border-top: 2px solid rgba(189, 189, 189, 0.25);
  border-bottom: unset;
  border-right: unset;
  border-left: unset;
  border-radius: unset;
}
.tablet .financeItem .financeItem-card:last-child {
  padding-bottom: 35px;
  }
.tablet .financeItem .financeItem-card > *:first-child {
  grid-column: 6;
  grid-row: 1 / span 2;
  justify-self: flex-end;
  align-self: flex-start;
}
.tablet .financeItem .financeItem-card > *:nth-child(2){
  display: flex;
}

.tablet .financeItem .financeItem-card > *:nth-child(4) div:first-child,
.tablet .financeItem .financeItem-card > *:nth-child(5) div:first-child{
  text-transform: uppercase;
  font-size: 10px;
  font-weight: bold;
}
.tablet .financeItem .financeItem-card > *:nth-child(2), 
.tablet .financeItem .financeItem-card > *:nth-child(3){
 grid-column-start: 1;
 grid-column-end: 6;
}

.tablet .financeItem .financeItem-card > *:last-child {
  grid-column-start: 6;
  grid-column-end: 7;
}