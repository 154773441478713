.guardianSummary {
  display: grid;
  grid-template-columns: repeat(5, max-content);
  grid-template-rows: auto;
  grid-column-gap: 30px;
  grid-row-gap: 10px;
  padding-bottom: 20px;
}
.mobile .guardianSummary {
  grid-template-columns: max-content repeat(4, minmax(auto, 20%));
  width: max-content;
  grid-column-gap: 15px;
}
.guardianSummary-avatar {
  grid-row: span 2;
}
.guardianSummary > *:nth-child(2) {
  grid-column: span 4;
}
.mobile .guardianSummary >*:nth-child(3){
  grid-column: span 3;
}
