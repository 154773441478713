.StudentBioData {
    display: grid;
    grid-template-columns: 1.2fr 1fr;
    grid-template-rows: max-content max-content;
    grid-gap: 10px;
}
.tablet .StudentBioData {
    grid-template-columns: repeat(2, 1fr);
}
.mobile .StudentBioData {
    grid-template-columns: unset;
}
.StudentBioData > *:first-child {
    grid-row: span 2;
}