/* Term Select */
.SelectButtonGroup {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    background: var(--grey100);
    border-radius: 4px;
    padding: 5px;
    font-size: 13px;
    font-weight: bold;
    border: 1px solid white;
    width: max-content;
}
.SelectButtonGroup--mobile {
    font-size: 11px;
    width: stretch;
    justify-content: space-between;
}
.SelectButtonGroup--mobile .SelectButtonGroup-button {
    padding: 7.5px;
}
.SelectButtonGroup-button {
    border-radius: inherit;
    color: var(--grey);
    padding: 10px;
    display: flex;
    align-items: center;
    transition: background .3s ease;
    font-size: 12px;
    white-space: nowrap;
}
.mobile .SelectButtonGroup-button {
    font-size: 11px;
    font-weight: bold;
}
.SelectButtonGroup-button-isActive {
    background: white !important;
    color: black;
    font-weight: bold;
}
.SelectButtonGroup-button + .SelectButtonGroup-button {
    margin-left: 10px;
}

.mobile .SelectButtonGroup-button + .SelectButtonGroup-button {
    margin-left: 0;
}