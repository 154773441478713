.Settings .ClassSets {
    width: 100%;
    padding-top: 10px;
}

.mobile .Settings .ClassSets,
.tablet .Settings .ClassSets {
    padding: 29px 20px;
}

.Settings .ClassSets--Body {
    width: 100%;
    height: calc(100vh - 300px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.Settings .ClassSets--Header {
    width: 100%;
}

.Settings .ClassSets--Header h2 {
    color: #6d6d6d;
}
