.Calendar {
  width: stretch;
}
.Calendar-header {
  font-weight: 700;
  font-size: 16px;
  padding: 10px 5px;
}
.Calendar-header-isNavigationEnabled {
  display: grid;
  grid-template-columns: min-content auto min-content;
  grid-gap: 10px;
  text-align: center;
}
.Calendar-days {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 15px;
  text-align: center;
  padding: 10px;
  font-size: 12px;
}
.Calendar-header .material-icons {
  color: var(--grey);
}
.Calendar-body {
  padding-top: 10px;
}
