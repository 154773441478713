.NoteItem {
  margin-bottom: 10px;
  display: grid;
  justify-content: space-between;
  align-items: center;
}
.mobile .NoteItem {
  grid-row-gap: 15px;
  grid-template-columns: 60px auto 10px;
  grid-template-rows: auto;
  grid-template-areas:
    "title              title             title"
    "text               text              text"
    "createdAt          author            author"
    "visibilitySwitch    visibilitySwitch  moreButton"
}
.tablet .NoteItem {
  grid-gap: 15px;
  grid-template-columns: auto 50px 200px min-content;
  grid-template-rows: auto;
  grid-template-areas:
    "title            title     title   title"
    "text             text      text    text"
    "visibilitySwitch createdAt author  moreButton";
}
.desktop .NoteItem {
  grid-gap: 15px;
  grid-template-columns: 1fr max-content min-content;
  grid-template-rows: auto;
  grid-template-areas:
    "title              createdAt   moreButton"
    "text               .           ."
    "visibilitySwitch   author      .";
}
.desktop .NoteItem .NoteItem-text,
.desktop .NoteItem .NoteItem-author {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.NoteItem-title {
  grid-area: title;
}
.NoteItem-text {
  grid-area: text;
}
.desktop .NoteItem .NoteItem-createdAt {
  justify-self: flex-end;
}
.NoteItem-createdAt {
  grid-area: createdAt;
}
.NoteItem-author {
  grid-area: author;
}
.NoteItem-visibilitySwitch {
  grid-area: visibilitySwitch;
}
.NoteItem-moreButton {
  grid-area: moreButton;
  padding: 0 5px;
}