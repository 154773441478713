.badgeWrapper {
    padding: 5px;
    padding-left: 10px;
    display: inline-block;
    align-items: center;
}

.numberCircle {
    border-radius: 50%;
    font-size: 12px;
    color: #fff;
    text-align: center;
    width: 20px;
    height: 20px;
    padding: 0 6px;
    font-weight: normal;
    line-height: 20px;
    background: #f5222d;
    -webkit-box-shadow: 0 0 0 1px #fff;
    box-shadow: 0 0 0 1px #fff;
    display: inline-block;
    margin-left: -5px;
    margin-top: -5px;
}

.numberCircle.missing{
    background-color: var(--dangerColor);
    padding-left: 5px; /* HACK: shift one pixel left to look centralized */
}

.numberCircle.collected{
    background-color: var(--infoColor);
}

.numberCircle.late{
    background-color: var(--warningColor);
}