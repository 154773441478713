.BatchCollectionSearch-TabletForm {
    display: grid;
    grid-template-columns: 2fr 2fr 1fr;
    align-items: center;
    grid-column-gap: 15px;
    margin-bottom: -10px;
}

.BatchCollectionSearch-DesktopForm {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    grid-column-gap: 15px;
}