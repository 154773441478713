.PhotoInputDisplay {
    height: 200px;
    width: 85%;
}

.mobile .PhotoInputDisplay,
.tablet .PhotoInputDisplay {
    width: 60%;
    height: auto;
    margin: auto;
}

.PhotoInputDisplay img {
    width: 100%;
    height: 90%;
    object-fit: fill;
    border-radius: 5px;
}

.mobile .PhotoInputDisplay img,
.tablet .PhotoInputDisplay img {
    height: auto;
}

.PhotoInputDisplay--remove-image {
    color: #EB2828;
    display: block;
    text-align: center;
    margin-top: 16px;
}

.PhotoInputDisplay--remove-image:hover {
    text-decoration: underline;
    cursor: pointer;
}
