.Pagination {
  padding: 15px 10px ;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.Pagination-button {
  background: #FFFFFF;
  border: 0.708823px solid #DCDCDC !important;
  box-sizing: border-box;
  border-radius: 2.83529px;
  padding: 10px 15px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.Pagination-button:first-of-type {
  margin: 0 10px;
}
.Pagination-button img {
  width: 12px;
  height: 12px;
}
.mobile .Pagination {
  width: 100%;
  justify-content: space-between;
}
.mobile .Pagination .Pagination-button:first-of-type {
  margin: 0 20px;
}