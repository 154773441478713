.BatchAttendanceRegister {
    position: relative;
}

.BatchAttendanceRegister-title {
    text-transform: uppercase;
    font-weight: bold;
}

.tablet .BatchAttendanceRegister-title,
.mobile .BatchAttendanceRegister-title {
    text-align: center;
    margin-bottom: 30px;
}

.BatchAttendanceRegister-nav {
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 16px;
    font-weight: 800;
    width: 25%;
    margin: auto;
    padding-bottom: 15px;
}

.tablet .BatchAttendanceRegister-nav {
    width: 50%;
}

.mobile .BatchAttendanceRegister-nav {
    width: 100%;
}

.BatchAttendanceRegister-grid {
    overflow-x: auto;
    max-height: 500px;
    position: relative;
    display: flex;
}

.mobile .BatchAttendanceRegister-grid {
    max-height: 80vh;
}

.BatchAttendanceRegister-cell:first-child {
    position: sticky;
    top: 0;
    background: white;
    z-index: 2;
    font-size: 10px;
    font-weight: 600;
    justify-content: flex-start;
}

.BatchAttendanceRegister-cell:last-child {
    border-bottom: none;
}

.BatchAttendanceRegister-cell:nth-child(even) {
    background: var(--grey100);
}

.BatchAttendanceRegister-cell {
    height: 50px;
    border-bottom: 1px solid var(--grey200);
    border-right: 1px solid var(--grey200);
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    text-transform: uppercase;
}

.BatchAttendanceRegister-leftPane {
    width: max-content;
    white-space: nowrap;
}

.BatchAttendanceRegister-leftPane .BatchAttendanceRegister-cell {
    justify-content: flex-start;
}

.BatchAttendanceRegister-col,
.BatchAttendanceRegister-col > * {
    transition: background .4s ease-in-out;
}

.BatchAttendanceRegister-col:first-child {
    position: sticky;
    left: 0;
    z-index: 3;
    background: white;
}

.BatchAttendanceRegister-col {
    height: 50%;
    position: relative;
}

.BatchAttendanceRegister-col:not(:first-child):hover::after {
    background: var(--warningColor100) !important;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    pointer-events: none;
}