.Settings .SchoolDetails {
    width: 90%;
    margin: auto;
    padding-top: 10px;
}

.Settings .Wrapper-content {
    background-color: #fff;
}

.Settings .SchoolDetails--Header {
    margin-bottom: 54px;
}

.mobile .Settings .SchoolDetails--Header,
.tablet .Settings .SchoolDetails--Header {
    margin-bottom: 32px;
}

.mobile .Settings .SchoolDetails--Image,
.tablet .Settings .SchoolDetails--Image {
    margin-bottom: 40px;
}

.Settings .SchoolDetails--Body {
    display: grid;
    grid-template-columns: 267px auto;
}

.mobile .Settings .SchoolDetails--Body,
.tablet .Settings .SchoolDetails--Body {
    display: grid;
    grid-template-columns: auto;
}

.Settings .school-name-wrapper {
    margin-bottom: 37px;
    width: 100%;
}

.mobile .Settings .school-name-wrapper,
.tablet .Settings .school-name-wrapper {
    margin-bottom: 0px;
}

.Settings .inline-item-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 27px;
}

.mobile .Settings .inline-item-wrapper,
.tablet .Settings .inline-item-wrapper {
    flex-direction: column;
    margin-bottom: 0px;
}

.Settings .inline-item-wrapper > *,
.Settings .address-country-currency-wrapper > * {
    width: 48%;
}

.mobile .Settings .inline-item-wrapper > *,
.tablet .Settings .inline-item-wrapper > *,
.mobile .Settings .address-country-currency-wrapper > *,
.tablet .Settings .address-country-currency-wrapper > * {
    width: 100%;
}

.Settings .address-country-currency-wrapper {
    display: flex;
    justify-content: space-between;
}

.mobile .Settings .address-country-currency-wrapper,
.tablet .Settings .address-country-currency-wrapper {
    flex-direction: column;
}

.Settings .country-currency-wrapper {
    display: flex;
    flex-direction: column;
}

.Settings .address .FormInputControl {
    height: auto;
}

.Settings .address .FormInputControl textarea {
    height: 102px;
}

.Settings .SchoolDetails--Fields button {
    float: right;
    margin-top: 30px;
    padding: 0 40px;
}

.mobile .Settings .SchoolDetails--Fields button,
.tablet .Settings .SchoolDetails--Fields button {
    margin-top: 10px;
}
