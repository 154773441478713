.DateRangePicker {
  display: grid;
  grid-template-columns: minmax(200px, 1fr) auto;
  grid-column-gap: 30px;
  background: white;
  padding: 10px;
}
.DateRangePicker-hide-options {
  grid-column-gap: 0;
}
.tablet.DateRangePicker,
.mobile.DateRangePicker {
  grid-template-columns: auto;
  grid-template-rows: auto auto;
  grid-row-gap: 10px;
}
.mobile.DateRangePicker {
  width: 80vw;
}
.DateRangePicker-options {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.tablet.DateRangePicker .DateRangePicker-options {
  flex-direction: row;
}
.DateRangePicker-option {
  width: stretch;
  color: var(--blue);
}
.DateRangePicker-option-isActive {
  color: white;
}
.tablet.DateRangePicker .DateRangePicker-option:not(:last-child) {
  margin-right: 10px;
}
.desktop.DateRangePicker .DateRangePicker-option {
  height: 40px;
}
.mobile.DateRangePicker .DateRangePicker-option:not(:last-child) {
  margin-bottom: 10px;
}
.DateRangePicker-input {
  display: flex;
  align-items: center;
  border: 1px solid var(--grey200);
  border-radius: 4px;
  padding: 10px;
  position: relative;
}
.DateRangePicker-input img {
  margin-right: 10px;
  width: 20px;
  height: 20px;
}
.DateRangePicker-input > *:not(img) {
  border: none;
  outline: none;
  width: stretch;
  font-size: 13px;
  font-weight: 500;
}
.mobile.DateRangePicker .DateRangePicker-input > *:not(img) {
  text-align: center;
}
.DateRangePicker-calendarGroup {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 30px;
}
.mobile.DateRangePicker .DateRangePicker-calendarGroup {
  grid-template-columns: auto;
  grid-template-rows: auto;
  grid-row-gap: 20px;
}
.DateRangePicker-calendar {
  user-select: none;
}
.DateRangePicker-calendarNav,
.DateRangePicker-calendarWeekdays {
  border-bottom: 1px solid var(--grey200);
}
.DateRangePicker-calendarNav {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 10px;
  font-size: 13px;
}
.DateRangePicker-calendarNavDate{
  color: var(--blue);
  font-weight: bold;
}
.DateRangePicker-calendarNavLeft,
.DateRangePicker-calendarNavRight {
  position: absolute;
  color: var(--grey);
}
.DateRangePicker-calendarNavLeft {
  left: 0;
}
.DateRangePicker-calendarNavRight {
  right: 0;
}
.DateRangePicker-calendarWeekdays,
.DateRangePicker-calendarDays {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  text-align: center;
  padding: 10px;
  line-height: 0;
  font-size: 10px;
  font-weight: 600;
}
.DateRangePicker-calendarWeekdays {
  color: var(--grey);
}
.DateRangePicker-calendarWeekday{
  padding: 10px;
}
.DateRangePicker-calendarDays {
  grid-row-gap: 7.5px;
}
.DateRangePicker-calendarDay {
  cursor: pointer;
  transition: all .3s ease;
  padding: 12.5px 10px;
}
.mobile.DateRangePicker .DateRangePicker-calendarDay {
  padding: 15px 10px;
}
.DateRangePicker-calendarDay-isBetweenRange {
  background: var(--blue300);
}
.DateRangePicker-calendarDay-isRangeStart,
.DateRangePicker-calendarDay-isRangeEnd {
  background: var(--blue);
  color: white;
  font-weight: bold;
}
.DateRangePicker-calendarDay-isRangeStart {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.DateRangePicker-calendarDay-isRangeEnd {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.DateRangePicker-calendarDay:not(.DateRangePicker-calendarDay-isRangeStart):not(.DateRangePicker-calendarDay-isRangeEnd):hover {
  background: var(--blue200);
}
.DateRangePicker-calendarDay:not(.DateRangePicker-calendarDay-isRangeStart):not(.DateRangePicker-calendarDay-isRangeEnd):active {
  background: var(--blue100);
}
.DateRangePicker-calendarDay-isDisabled {
  pointer-events: none;
  color: var(--grey);
  background: transparent;
  font-weight: normal;
}