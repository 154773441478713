.BatchAttendanceReport-print {
  position: absolute;
  top: 0;
  right: 0;
  margin: 15px;
}
.tablet .BatchAttendanceReport-summary1,
.desktop .BatchAttendanceReport-summary1  {
  display: flex;
}
.tablet .BatchAttendanceReport-summary2,
.desktop .BatchAttendanceReport-summary2  {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 85px;;
  align-items: center;
}
.mobile .BatchAttendanceReport-summary2 {
  display: grid;
  grid-template-columns: repeat(2, minmax(60px, 1fr));
  grid-column-gap: 60px;
  align-items: center;
  padding-top: 1.4rem;
  padding-bottom: 5px;
}


.mobile .BatchAttendanceReport-details{
  display: grid;
  grid-template-columns: repeat(2, minmax(20px, 1fr));;
  align-items: center;
}
.mobile .BatchAttendanceReport-details > *{
  justify-self: center;
  padding: 10px;
}