.discountForStudents-item {
    padding: 10px 3px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 15px;
}

.discountForStudents-item>div>div {
    font-size: 12px;
    line-height: 19px;
}

.discountForStudents-item>div:first-child {
    grid-column: 1 / span 2;
}

.discountForStudents-item>div>div:first-child {
    font-size: 10px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 5px;
}

.discountForStudents-items--tablet {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
}

.discountForStudents-items--mobile {
    display: block;
}

.discountForStudents-items--mobile .Card {
    margin-bottom: 10px;
}