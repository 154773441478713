.debtors {}
.debtors-head {
  margin-bottom: 5px;
}
.debtors-tableRow {
  grid-template-columns: repeat(4, 1fr);
}
.mobile .debtors {
  display: grid;
  grid-template-rows: auto;
  grid-row-gap: 10px;
  padding-bottom: 30px;
}
.debtors-mobileCard {
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;
  line-height: 2;
}
.debtors-mobileCard > div:last-child {
  border-left: 1px solid var(--grey200);
  text-align: center;
}
.tablet .debtors .debtors-tableRow {
  grid-template-columns: repeat(3, 2fr) 1fr;
}
.tablet .debtors .debtors-tableBody {
  min-height: 200px;
}