.tabs {
  position: relative;
  margin: -10px;
  margin-bottom: 60px;
  background: #FFFFFF;
  border: 1px solid rgba(189, 189, 189, 0.25);
  box-shadow: 0px 2px 9px rgba(196, 196, 196, 0.11);
  border-radius: 5px;
  padding: 15px;
  transition: padding .15s ease-out;
  box-sizing: unset;
}
.tablet .tabs:not(.tabs-isNotResponsive),
.mobile .tabs:not(.tabs-isNotResponsive) {
  border-radius: 0 !important;
  border: none !important;
}
.desktop .tabs,
.tabs-isNotResponsive {
  margin: 0;
  margin-bottom: 10px;
  padding-bottom: 0 !important;
}


.tablet .tabs-isNotResponsive {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: flex-start;
}

.mobile .tabs-isNotResponsive {
  display: grid;
  justify-content: flex-start;
}
.tabs-container {
  display: inline-block;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -50px;
  grid-area: tabs;
  white-space: nowrap;
  overflow-x: visible;
  overflow-y: hidden;
  padding: 0 5px;
}
.tablet .tabs:not(.tabs-isNotResponsive) .tabs-container {
  display: inline-flex;
  justify-content: center;
}
.desktop .tabs .tabs-container,
.tabs-isNotResponsive .tabs-container {
  position: relative;
  left: unset;
  right: unset;
  bottom: unset;
  overflow: visible;
  padding: 0;
}
.tabs-container .tab {
  display: inline-block;
  vertical-align: top;
  background: white;
  padding: 10px 20px;
  margin: 0 5px;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
  user-select: none;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.desktop .tabs .tabs-container .tab,
.tabs-isNotResponsive .tabs-container .tab {
  margin: 0;
  margin-right: 15px;
  border-radius: 0;
  border: 1px solid white;
  box-shadow: unset;
}
.desktop .tabs .tabs-container .tab::before,
.desktop .tabs .tabs-container .tab::after,
.tabs-isNotResponsive .tabs-container .tab::before,
.tabs-isNotResponsive .tabs-container .tab::after {
  content: '';
  position: absolute;
  width: 100%;
  left: 0;
  height: 0;
}
.desktop .tabs .tabs-container .tab::before,
.tabs-isNotResponsive .tabs-container .tab::before {
  transition: height 600ms cubic-bezier(0.19, 1, 0.22, 1);
}
.tabs-container .tab-isActive {
  background: var(--blue);
  color: white;
}
.desktop .tabs .tabs-container .tab-isActive,
.tabs-isNotResponsive .tabs-container .tab-isActive {
  background: var(--grey100);
  color: var(--blue);
  border-color: rgba(189, 189, 189, 0.15);
  position: relative;
  box-shadow: inset 0px 2px 9px rgba(196, 196, 196, 0.11);
}
.desktop .tabs .tabs-container .tab-isActive::before,
.tabs-isNotResponsive .tabs-container .tab-isActive::before {
  background: var(--blue);
  top: 0;
  height: 3px;
}
.desktop .tabs .tabs-container .tab-isActive::after,
.tabs-isNotResponsive .tabs-container .tab-isActive::after {
  background: var(--grey100);
  top: calc(100% - 10px);
  height: 20px;
}
.tablet .tabs-container,
.mobile .tabs-container {
  grid-column: 1;
}
.tabs-grid {
  display: grid;
  grid-template-columns: 1fr minmax(min-content, 30%);
  grid-column-gap: 30px;
  grid-row-gap: 10px;
  grid-template-areas:
    "title    options"
    "content  content";
}
.tablet .tabs:not(.tabs-isNotResponsive) .tabs-grid {
  grid-template-columns: 1fr minmax(min-content, 15%);
  grid-template-areas:
    "title    title"
    "content  options";
}
.desktop .tabs .tabs-grid {
  grid-template-columns: 1fr min-content;
  grid-template-areas:
    "content  options"
    "tabs     tabs";
}
.tabs-isNotResponsive {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
}
.desktop .tabs-isNotResponsive .tabs-grid {
  display: flex;
  width: stretch;
  margin-left: 5%;
  margin-top: -15px;
}
.tablet .tabs-isNotResponsive .tabs-grid,
.mobile .tabs-isNotResponsive .tabs-grid {
  margin-top: -15px;
}
.tabs-isNotResponsive .tabs-content {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  width: stretch;
}
.tabs-options {
  grid-area: options;
  display: grid;
  grid-template-rows: max-content;
  grid-gap: 30px;
  align-items: flex-start;
}
.tabs-title {
  grid-area: title;
}
.tabs-content {
  grid-area: content;
}