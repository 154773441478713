* {   
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
body {
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  color: #3B3B3B;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
  background: var(--grey100);
  -webkit-overflow-scrolling: touch;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
a {
  color: var(--blue);
}
a,
a:focus,
a:active {
  text-decoration: none;
}
a:hover {
  color: unset;
}