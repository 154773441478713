.FullscreenDialog {
    position: fixed;
    height: 100vh;
    height: var(--appHeight);
    background: var(--blueGradient);
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
    padding-bottom: 100px;
    overflow-y: auto;
    color: white;
    opacity: 1;
}

.FullscreenDialog-isHidden {
    opacity: 0;
    pointer-events: none;
    /* transition: var(--fadeTransition); */
}

.FullscreenDialog-title {
    text-align: center;
    padding: 30px 10px;
    font-size: 20px;
}

.FullscreenDialog-close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 33px 20px;
}

.FullscreenDialog-content {
    padding: 10px;
}

.FullscreenDialog-confirmButton {
    position: fixed !important;
    bottom: 0;
    left: 0;
    right: 0;
    font-weight: 600;
    font-size: 16px;
    padding: 20px;
    text-align: center;
    background: var(--grey100) !important;
    color: var(--blue);
    box-shadow: 0px -2px 2px rgba(0, 0, 0, 0.05);
    opacity: 0.98;
    width: 100vw;
}