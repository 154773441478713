.desktop .selectedFee {
  background: white;
}

.selectedFee-back {
  color: var(--blue);
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 1px;
  margin-bottom: 20px;
}

.selectedFee .color-blue,
.tablet .color-blue,
.mobile .color-blue {
  color: var(--blue);
}

.selectedFee-table table tbody tr td:last-child {
  text-align: right;
}

.selectedFee-table {
  border-top: 1px solid var(--grey200);
}

.selectedFee-head {
  display: flex;
  justify-content: space-between;
}

.selectedFee-dataGroup {
  display: grid;
  grid-template-columns: repeat(7, auto);
  justify-content: space-between;
  grid-gap: 28px;
  flex: 1;
}
.desktop .selectedFee-dataGroup  div:nth-child(6) {
  display: grid;
  grid-column: 6 /8;
}
.tablet .selectedFee-dataGroup {
  grid-template-columns: repeat(6, auto);
  grid-row-gap: 22px;
}

.mobile .selectedFee-dataGroup {
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 22px;
}

.tablet .selectedFee-head,
.mobile .selectedFee-head {
  justify-content: center;
  flex-direction: column;
  background: white;
  margin: -10px -10px 10px -10px;
  padding: 20px;
  position: relative;
}

.selectedFee-head>* {
  padding-bottom: 40px;
}

.selectedFee-popover>div {
  cursor: pointer;
  font-size: 12px;
  line-height: 19px;
  position: relative;
}

.selectedFee-popover--options>div {
  padding: 12px 10px;
}

div.selectedFee-popover--change-status {
  padding: 0;
}

.selectedFee-popover--change-status .ant-collapse-item.ant-collapse-item>div {
  color: rgba(0, 0, 0, 0.65);
}

.selectedFee-popover--change-status .ant-collapse-content-box div>* {
  margin-right: 12px;
}

.selectedFee-popover--change-status .ant-collapse-content-box>div:not(:last-child) {
  border-bottom: 1px solid rgba(189, 189, 189, 0.25);
  padding-bottom: 14px;
}

.selectedFee-popover--change-status .ant-collapse-content-box>div:not(:first-child) {
  padding-top: 14px;
}

.selectedFee-popover--change-status .ant-collapse-content-box>div {
  padding-left: 9px
}

.selectedFee-popover--change-status .ant-collapse-content-box {
  padding-left: 0;
}

div.selectedFee-popover--selected-students {
  padding-right: 47px;
}

.selectedFee-popover--selected-students span {
  display: inline-block;
  width: 26px;
  height: 26px;
  border-radius: 25px;
  background: var(--blue);
  text-align: center;
  color: white;
  position: absolute;
  padding-top: 3px;
  right: 3px;
  top: 10px;
}


.selectedFee-popover--more>div {
  padding: 9px 51px 9px 17px;
}

.selectedFee-popover>div:not(:last-child) {
  border-bottom: 1px solid rgba(189, 189, 189, 0.25);
}

.tablet .selectedFee-head>*:not(.selectedFee-optionGroup) {
  padding: 0;
}

.mobile .selectedFee-head>*:not(.selectedFee-optionGroup) {
  padding-right: 0;
  padding-bottom: 15px;
  flex-basis: 50%;
}

.selectedFee .selectedFee-optionGroup {
  order: 1;
  margin-top: -7.5px;
  margin-left: 44px;
  align-self: flex-end;
}

.selectedFee-more {
  display: flex;
  justify-content: flex-end;
  margin-right: -30px;
}

.selectedFee-more::after,
.selectedFee-more:hover::after {
  background: transparent;
}

.selectedFee-optionGroup .Button--primary img {
  margin-left: 37px;
}

.tablet .selectedFee-optionGroup,
.mobile .selectedFee-optionGroup {
  order: 0;
  width: stretch;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 30px;
}

.selectedFee-optionGroup {
  margin: 0;
  display: flex;
  align-items: flex-start;
}
.tablet .option--button,
.mobile .option--button{
  position: absolute;
  top: 10px;
  right: 15px;
}
.tablet .selectedFee-more {
  width: 100%;
  padding-right: 12px;
  margin-right: 0;
}


.tablet .selectedFee-item {
  display: grid;
  grid-template-columns: 40px 2fr repeat(4, 1fr);
  grid-column-gap: 15px;
  align-items: center;
  padding-left: 15px;
  margin-bottom: 0;
}
/* .tablet .selectedFee div:nth-child(2){
  padding: 25px;
} */

.tablet>.selectedFee-item {
  margin: 25px 15px 15px 15px;
  background: var(--grey100);
}

.tablet .Card:not(:last-child) {
  margin-bottom: 13px;
}

.tablet .selectedFee-item img {
  background: var(--grey100);
  justify-self: flex-end;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.tablet .selectedFee-more img {
  width: initial;
  height: initial;
  background: none;
  border-radius: 0;
}

.mobile .selectedFee-item {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 21px;
  grid-column-gap: 20px;
  
}

.mobile .selectedFee-item>div:first-child {
  grid-column: 1/ span 2;
  display: flex;
}

.mobile .selectedFee-more {
  margin: 0 0 0 auto;
}

.mobile .selectedFee-item>div {
  letter-spacing: 1px;
  white-space: nowrap;
}

.mobile .selectedFee-item>div:not(:first-child)>div:first-child {
  text-transform: uppercase;
  font-size: 10px;
  font-weight: bold;
}

.mobile .selectedFee-item>div:not(:first-child)>div:last-child {
  font-size: 12px;
}

.mobile .selectedFee-item>div:first-child {
  font-size: 14px;
}

.mobile .selectedFee-item>div:first-child .Avatar {
  margin-right: 15px;
}

.mobile .Card:not(:last-child) {
  /* FIXME: This line of code affects the app globally. Make selectors more specific. Tested in development */
  margin-bottom: 13px;
}