:root {
    --appHeight: calc(var(--vh, 1vh) * 100);
    --blueGradient: linear-gradient(136.25deg, #1B75BB 0%, #00ADEE 100%);
    --blue: #1C75BB;
    --blue100: rgba(238, 245, 250, 0.43);
    --blue200: rgba(117, 175, 217, 0.12);
    --blue300: rgba(117, 175, 217, 0.3);
    --dangerColor: rgb(251, 93, 93);
    --dangerColor100: rgba(251, 93, 93, 0.1);
    --deepblue: #2D74DA;
    --fadeTransition: all 300ms cubic-bezier(0.77, 0, 0.175, 1);
    --FormInput-inline-label-minwidth: 110px;
    --FormInput-inline-label-width: 10%;
    --grey: #BDBDBD;
    --grey100: #F5F5F5;
    --grey200: #EFEFEF;
    --grey300: hsla(0,0%,74%,.25);
    --inputControlPlaceholderColor: var(--grey);
    --inputControlTextColor: #3B3B3B;
    --schoolSwap-height: 60px;
    --sidebar-width: 245px;
    --successColor: rgb(23, 191, 95);
    --successColor100: rgba(23, 191, 95, 0.1);
    --warningColor: rgb(250,217,97);
    --warningColor100: rgba(250,217,97, 0.2);
    --infoColor: #00ADEE;
    --yellowGradient: linear-gradient(28.37deg, #FAD961 22.52%, #F76B1C 213.73%);
  }
