.Button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    flex-grow: 0;
    font-weight: 600;
    user-select: none;
    text-align: center;
    width: fit-content;
    min-width: 80px;
    transition: all .15s ease-in-out;
    border-radius: 3px;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    white-space: nowrap;
    text-transform: none;
    letter-spacing: 0;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    /* box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045); */
}

.Button--large {
    height: 45px;
    padding: 0 15px;
}

.Button--small {
    font-weight: 700 !important;
    height: 30px;
}

.Button--primary, .Button--primary:hover {
    background: var(--blue);
    color: white;
    border-color: var(--blue);
}

.Button--primary:hover, .Button--outline:hover {
    outline: 0;
    box-shadow: 0 0 0 0.2rem var(--blue200);
}

.Button--secondary {
    background: var(--grey200);
    color: black;
    border-color: var(--grey300);
}

.Button--success {
    background: var(--successColor);
    color: white;
    border-color: var(--successColor);
}

.Button--success:hover {
    outline: 0;
    box-shadow: 0 0 0 0.2rem var(--successColor100);
}

.Button--warning {
    background: var(--warningColor);
    color: white;
    border-color: var(--warningColor);
}

.Button--warning:hover {
    outline: 0;
    box-shadow: 0 0 0 0.2rem var(--warningColor100);
}

.Button--danger {
    background: var(--dangerColor);
    color: white;
    border-color: var(--dangerColor);
}

.Button--danger:hover {
    outline: 0;
    box-shadow: 0 0 0 0.2rem var(--dangerColor100);
}

.Button--outline {
    background: transparent;
    color: var(--blue);
    border-color: var(--blue);
}

.Button--clear {
    border: none;
    border-radius: 0;
    justify-content: flex-start;
    font-weight: unset;
    padding: 8px 2px;
    box-shadow: none;
    background: transparent;
}

.Button--disabled {
    opacity: 0.6;
    cursor: not-allowed;
    outline: none;
}

.Button--flat {
    border: none;
    padding: 0;
    color: var(--blue);
    box-shadow: none;
    background: transparent;
}

.Button--fullwidth {
    width: stretch;
}

div.Button .loader-spinner {
    display: inline-block;
    border: none;
    border-radius: 50%;
    border-top: 4px solid;
    height: 16px;
    width: 16px;
    margin-right: 5px;
    -webkit-animation: spin .6s infinite linear;
    animation: spin .6s infinite linear;
}