.touchable-opacity,
.touchable-highlight {
  cursor: pointer;
  user-select: none;
  transition: all .1s ease-in;
}
.touchable-opacity::after,
.touchable-highlight::after {
  pointer-events: none;
}
.touchable-opacity:hover {
  opacity: .7;
}
.touchable-opacity:active {
  opacity: .5;
}
.touchable-highlight {
  position: relative;
  overflow: hidden;
}
.touchable-highlight::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: black;
  opacity: 0;
  transition: opacity .2s;
  overflow: hidden;
}
.touchable-highlight:hover::after {
  opacity: .07;
}
.touchable-highlight:active::after {
  opacity: .15;
}
.touchable-highlight > * { z-index: 1; }
