.TransactionReceipt {}

.TransactionReceipt-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.TransactionReceipt-buttonGroup {
    border: 1px solid var(--grey100);
    border-radius: 4px;
    display: flex;
    align-items: center;
    overflow: hidden;
    justify-self: flex-end;
}

.TransactionReceipt-buttonGroup>*:first-child {
    border-right: 1px solid var(--grey100);
}

.TransactionReceipt-buttonGroup>* {
    padding: 5px 10px;
}

.TransactionReceiptStats {
    margin: 2rem 0;
    line-height: 1.5;
}

.tablet .TransactionReceiptStats {
    padding-left: 30px !important;
}

.tablet .TransactionReceiptStats, .mobile .TransactionReceiptStats {
    margin-bottom: 0;
}

#app:not(.mobile) .TransactionReceiptStats>* {
    margin-bottom: 1rem;
}

.TransactionReceiptStats>*>* {
    margin-top: 1rem;
}

.Row--bordered-top{
    border-top: 1px solid var(--grey200);
}

.wallet-transaction{
    padding-top: 10px;
}

.StudentTransactionsItem {
    margin-bottom: 10px;
    display: grid;
    grid-row-gap: 10px;
    grid-column-gap: 20px;
    grid-template-rows: auto;
    grid-template-columns: auto max-content;
}