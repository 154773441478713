.GuardianBioData {
    display: grid;
    grid-template-columns: 1.2fr 1fr;
    grid-template-rows: auto auto;
    grid-gap: 10px;
}
.tablet .GuardianBioData {
    grid-template-columns: repeat(2, 1fr);
}
.mobile .GuardianBioData {
    grid-template-columns: unset;
}
.GuardianBioData > *:first-child {
    grid-row: span 2;
}