.Feedback {
    padding: 30px;
    border-radius: 6px;
    position: fixed;
    top: 0;
    right: 0;
    display: grid;
    grid-template-columns: max-content auto;
    grid-column-gap: 30px;
    align-items: center;
    color: white;
    z-index: 20;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 1px;
    opacity: 0.99;
    margin: 10px 10px;
    box-shadow: 0px 13px 5px -3px rgba(0, 0, 0, 0.03);
    min-width: 100px;
}

.mobile .Feedback {
    width: calc(100% - 20px);
}

.Feedback--success {
    background: var(--successColor);
}

.Feedback--error {
    background: var(--dangerColor);
}

.Feedback--warning {
    background: var(--warningColor);
}

.Feedback--info {
    background: var(--infoColor);
}

/* .Feedback-isHidden {
    opacity: 0;
    pointer-events: none;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.03);
    transform: translateY(10px);
} */