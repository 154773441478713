.Settings .BackButtonMobile--cta-wrapper {
    display: flex;
    font-size: 12px;
    margin-bottom: 20px;
}

.Settings .BackButtonMobile--cta-wrapper:hover {
    cursor: pointer;
}

.Settings .BackButtonMobile--cta-wrapper img {
    width: 19px;
    height: 19px;
    margin-right: 10px;
}
