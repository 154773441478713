.recharts-text.recharts-polar-angle-axis-tick-value{
    font-size: 10px;
}

#studentAssessmentChartReports{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;
    margin-top: 20px;
}

#studentAssessmentChartReports .studentPerformanceTrend{
    order: 2;
    flex-grow: 3;
    min-width: 450px;
}

.mobile #studentAssessmentChartReports .studentPerformanceTrend{
    min-width: auto;
}

.mobile #studentAssessmentChartReports .studentPerformanceTrend div.Card{
    padding-left: 0;
}

#studentAssessmentChartReports .studentPerformanceRadar{
    order: 1;
    flex-grow: 1;
    width: 300px;
    margin-bottom: 0;
}

.StudentSubjectAssessment {
    display: grid;
    grid-gap: 15px;
    margin-top: 20px;
}

.mobile .StudentSubjectAssessment {
    padding: 20px 0;
}

.mobile .StudentSubjectAssessmentItem {
    position: relative;
    display: grid;
    grid-template-columns: repeat(3, auto);
    font-size: 16px;
    align-items: center;
    grid-row-gap: 15px;
}

.mobile .StudentSubjectAssessmentItem>div>span {
    font-size: 10px;
    color: var(--inputControlTextColor);
    display: block
}

.mobile .StudentSubjectAssessmentItem div.assCatBlock {
    grid-column: span 2;
}

.mobile .StudentSubjectAssessmentItem>div:nth-child(1) {
    grid-column: 1 /4;
}