.MultiselectInput {
  cursor: pointer;
}
.MultiselectInput .ant-checkbox-group {
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
}
.MultiselectInput .ant-checkbox-group label {
  margin-right: 0;
}
.MultiselectInput .ant-checkbox-group label + label {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid var(--grey200);
}