.CalendarCell {
  border-radius: 6px;
  border: 1px solid rgba(117, 175, 217, 0.3);
  padding: 5px 7.5px;
  color: var(--blue);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  cursor: default;
  user-select: none;
}
.desktop .CalendarCell {
  height: 2.5vw;
}
.tablet .CalendarCell {
  min-height: 6vw;
}
.mobile .CalendarCell {
  min-height: 12.5vw;
}
.tablet .CalendarCell,
.mobile .CalendarCell {
  font-size: 16px;
}
.CalendarCell--daysLate {
  background: rgba(250,217,97, 0.3);
  border-color: rgba(250,217,97, 0.3);
}
.CalendarCell--daysAbsent {
  background: rgba(251,93,93, 0.35);
  border-color: rgba(251,93,93, 0.35);
}
.CalendarCell--holiday {
  background: var(--grey200);
  border-color: var(--grey200);
}
.CalendarMemo {
  color: var(--blue);
  font-size: 9px;
}
.tablet .CalendarMemo,
.mobile .CalendarMemo {
  font-size: 14px;
}
.CalendarMemo .material-icons {
  position: absolute;
  bottom: 3px;
  right: 3px;
  color: inherit;
  font-size: inherit;
}
.CalendarMemo--daysAbsent {
  color: white;
}
.CalendarMemo--daysLate {
  color: var(--grey);
}