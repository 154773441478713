
.guardianFinance-head {
  display: grid;
  grid-column-gap: 20px;
  align-items: center;
}
.desktop .guardianFinance .guardianFinance-head {
  grid-template-columns: repeat(3, max-content);
  padding: 0 10px;
}
.tablet .guardianFinance .guardianFinance-head,
.mobile .guardianFinance .guardianFinance-head {
  grid-template-columns: max-content;
  padding-bottom: 10px;
  padding-right: 15px;
}
.tablet .guardianFinance .guardianFinance-head > *:first-child,
.mobile .guardianFinance .guardianFinance-head > *:first-child {
  grid-column: 3;
  grid-row: 1;
  justify-self: flex-end;
}
