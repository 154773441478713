.CreateNote {}
.CreateNote-title {
  border: none;
  outline: none;
  width: stretch;
}
.CreateNote-toolbar {
  border-bottom: 1px solid var(--grey200);
  padding: 15px 0;
  display: grid;
  grid-template-columns: repeat(2, max-content) auto;
  grid-gap: 15px;
  margin-bottom: 15px;
}
.CreateNote-toolbar > *:last-child {
  justify-self: flex-end;
}
.CreateNote-textarea {
  background: white;
  border: 1px solid rgba(189, 189, 189, 0.25);
  border-radius: 3px;
  padding: 15px;
  margin-bottom: 15px;
}
.CreateNote-textarea textarea {
  width: stretch;
  border: none;
  min-height: 300px;
  outline: none;
}
.CreateNote-buttonGroup {
  display: flex;
  align-items: center;
  justify-content: space-between;
}