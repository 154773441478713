.FinanceList {
    display: grid;
    grid-gap: 15px;
}
.mobile .FinanceListItem {
    position: relative;
}
.mobile .FinanceListItem > *+*:not(.FinanceListItem-checkbox):not(.FinanceListItem-avi) {
    margin-top: 10px;
}
.mobile .FinanceListItem-checkbox {
    position: absolute;
    right: 15px;
}
.mobile .FinanceListItem-avi {
    float: left;
    margin-right: 15px;
    margin-bottom: 15px;
}