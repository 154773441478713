.EmployeeList {
    display: grid;
    grid-gap: 15px;
}
.mobile .EmployeeListItem {
    position: relative;
}
.mobile .EmployeeListItem > *+*:not(.EmployeeListItem-checkbox):not(.EmployeeListItem-avi) {
    margin-top: 10px;
}
.mobile .EmployeeListItem-checkbox {
    position: absolute;
    right: 15px;
}
.mobile .EmployeeListItem-avi {
    float: left;
    margin-right: 15px;
    margin-bottom: 15px;
}