.dateInput-overlay {
  position: absolute;
  left: 0;
  z-index: 1;
  padding: 0 !important;
  margin: 13px 0;
}
.DayPicker {
  font-size: 0.9rem;
  color: var(--inputControlTextColor);
}
.DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: var(--blue100);
}
.DayPicker-Body {
  line-height: 1.25;
}
.DayPicker-wrapper {
  outline: none;
}
.dateInput-prepend {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  background: var(--grey100);
  border-right: 1px solid var(--grey200);
  border-top-left-radius: 3px !important;
  border-bottom-left-radius: 3px !important;
  height: stretch;
}
.dateInput-prepend img {
  width: 15px;
}