.SubjectList {
    display: grid;
    grid-gap: 15px;
}

.mobile .SubjectListItem {
    position: relative;
    display: grid;
    grid-template-columns: repeat(3, auto);
    font-size: 14px;
    align-items: center;
}

.mobile .SubjectListItem>div:last-child {
    text-align: right;
    grid-column: 3/ span 1;
}

.mobile .SubjectListItem--span-3 {
    grid-column: 1/ span 3;
}

.mobile a.SubjectListItem--span-3 {
    font-size: 16px;
    margin-bottom: 18px;
}

.mobile div.SubjectListItem--span-3 {
    font-size: 12px;
    color: #828282;
}


.SubjectList-popover>div {
    cursor: pointer;
    font-size: 12px;
    line-height: 19px;
    position: relative;
}


.SubjectList-popover--more>div {
    padding: 10px;
}

.SubjectList-popover>div:not(:last-child) {
    border-bottom: 1px solid rgba(189, 189, 189, 0.25);
}

.SubjectList-more {
    display: inline-block;
    padding: 0 12px;
}

.SubjectList-more::after,
.SubjectList-more:hover::after {
    background: transparent;
}

.mobile .SubjectList-more {
    padding: 0 0 0 12px;
}

.SubjectList .Table table tbody tr td:nth-of-type(6) {
    text-align: right;
}