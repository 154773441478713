.Settings .NewAcademicSession {
  overflow-y: scroll;
  padding: 10px 25px;
}

.mobile .Settings .NewAcademicSession,
.tablet .Settings .NewAcademicSession {
  padding: 10px 0;
}

.Settings .NewAcademicSession--Header {
  margin-bottom: 35px;
  color: #6d6d6d;
}

.Settings .NewAcademicSession--Header h2 {
  color: #6d6d6d;
}

.Settings .NewAcademicSession--Errors {
  padding: 35px 25px;
  background-color: #fef5f5;
  border-radius: 10px;
  margin-bottom: 35px;
}

.Settings .NewAcademicSession--Body {
  display: flex;
  flex-direction: column;
}

.mobile .Settings .NewAcademicSession--Body,
.tablet .Settings .NewAcademicSession--Body {
  display: flex;
  flex-direction: column;
}

.Settings .NewAcademicSession--Body .Label {
  margin-bottom: 25px;
}

.Settings .NewAcademicSession--Body button {
  align-self: flex-end;
  padding: 0 30px;
  margin-top: 15px;
  margin-bottom: 35px;
}

.mobile .Settings .NewAcademicSession--Body button,
.tablet .Settings .NewAcademicSession--Body button {
  align-self: center;
  width: 100%;
}
