.GuardianListSearch-TabletForm {
    display: grid;
    grid-template-columns: 3fr 1fr 1fr 1fr;
    align-items: center;
    grid-column-gap: 15px;
    margin-bottom: -10px;
}
.GuardianListSearch-DesktopForm {
    display: grid;
    grid-template-columns: 3fr 1fr 1fr 1fr 1fr;
    align-items: center;
    grid-column-gap: 15px;
}
.GuardianListSearch-DesktopForm > .GuardianListSearch-MoreFields {
    grid-column: span 5;
    display: grid;
    grid-column-gap: 15px;
    grid-template-columns: repeat(4, minmax(100px, auto));
    grid-template-rows: auto;
    margin-bottom: -10px;
}
.GuardianListSearch-DirectSearchForm {
    display: grid;
    grid-template-columns: auto max-content;
    grid-column-gap: 15px;
    align-items: center;
}
.mobile .GuardianListSearch-DirectSearchForm {
    grid-template-columns: auto;
}
.mobile .GuardianListSearch-DirectSearchForm > * {
    width: 100%;
}