.EmployeeListSearch-TabletForm {
    display: grid;
    grid-template-columns: 3fr 1fr 1fr 1fr;
    align-items: center;
    grid-column-gap: 15px;
    margin-bottom: -10px;
}
.EmployeeListSearch-DesktopForm {
    display: grid;
    grid-template-columns: 3fr 1fr 1fr 1fr 1fr;
    align-items: center;
    grid-column-gap: 15px;
}
.EmployeeListSearch-DesktopForm > .EmployeeListSearch-MoreFields {
    grid-column: span 5;
    display: grid;
    grid-column-gap: 15px;
    grid-template-columns: repeat(4, minmax(100px, auto));
    grid-template-rows: auto;
    margin-bottom: -10px;
}