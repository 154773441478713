.Label {
    margin-bottom: 15px;
}
.Label-text {
    font-size: 10px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 3px;
}
.Label--inline {
    display: grid;
    grid-template-columns: 30% auto;
    grid-gap: 15px;
}
.Label--inline .Label-text {
    margin-top: 15px;
}
.Label--required .Label-text {
    position: relative;
    padding-left: 10px;
}
.Label--required .Label-text::before {
    content: "*";
    color: var(--dangerColor);
    position: absolute;
    left: 0;
    font-size: 13px;
    font-weight: 800;
}