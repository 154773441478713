.Card {
    background: #FFFFFF;
    border: 1px solid rgba(189, 189, 189, 0.25);
    box-shadow: 0px 2px 9px rgba(196, 196, 196, 0.11);
    border-radius: 5px;
    padding: 15px;
    transition: padding .15s ease-out;
    box-sizing: unset;
}

.Card-title {
    letter-spacing: 1px;
    font-weight: 500;
    font-size: 10px;
    text-transform: uppercase;
    margin-bottom: 30px;
}
.mobile .Card-title,
.tablet .Card-title {
    text-align: center;
}