.sidebar {
    background: var(--blueGradient);
    display: grid;
    grid-template-rows: auto auto;
    overflow: auto;
    color: white;
    font-weight: bold;
    font-size: 13px;
}

.sidebar a:hover:not(.help) {
    color: white;
}

.tablet .sidebar .sidebar-head,
.mobile .sidebar .sidebar-head {
    padding: 10px 50px 10px 15px;
}

.sidebar-head-isExpanded {
    background: var(--blueGradient);
}

.sidebarToggle {
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    height: 60px;
    z-index: 6;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.sidebarToggle-icon {
    width: 18px;
    height: 18px;
}

.sidebarToggle-isFixed {
    position: fixed;
}

.sidebarToggle-text {
    font-size: 10px;
    font-weight: bold;
}

.sidebarItemList {
    overflow: auto;
}

.sidebarItem {
    padding: 15px 30px;
}

.sidebarItem-collapseItem {
    padding: 10px 15px;
}

.sidebarItem,
.sidebarItem-collapseItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: white;
    text-decoration: none;
}

.sidebarItem > div {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.sidebarItem-caret {
    transition: transform 0.4s;
}

.sidebarItem-isActive .sidebarItem-icon:not(.sidebarItem-icon-isActive) {
    /**
   * when .sidebarItem isActive, don't display icons without .sidebarItem-icon-isActive class
   */
    display: none;
}

.sidebarItem-isActive .sidebarItem-icon-isActive {
    /**
   * when .sidebarItem isActive, display icons with .sidebarItem-icon-isActive class
   */
    display: visible;
}

.sidebarItem:not(.sidebarItem-isActive) .sidebarItem-icon-isActive {
    /**
   * when .sidebarItem is not isActive, don't display icons with .sidebarItem-icon-isActive class
   */
    display: none;
}

.sidebarItem-isOpen .sidebarItem-caret {
    transform: rotate(180deg);
}

.sidebarItem-isSettingsPaneOpen {
    padding: 15px 25px;
}

.sidebarItem-icon {
    margin-right: 10px;
    width: 21px;
    height: 21px;
}

.sidebarItem-collapse {
    margin-left: 70px;
    border-left: 1px dashed #fff;
}

.sidebarItem-collapseItem {
    overflow: visible !important;
}

.sidebarItem-collapseItem-isActive::before {
    background: var(--yellowGradient) !important;
}

.sidebarItem-collapseItem::before {
    content: "";
    position: absolute;
    left: -5px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    background: white;
    height: 8px;
    width: 8px;
    border-radius: 8px;
}

.sidebarFooter {
}

.sidebarFooter-divider {
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    margin: 0 20px 10px;
}

.sidebarFooter-actions,
.sidebarFooter-action {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.sidebarFooter-actions {
    justify-content: space-between;
    padding: 10px 20px;
}

.sidebarFooter-action {
    background: white;
    padding: 5px 20px;
    color: var(--blue);
    border-radius: 20px;
}

.sidebarFooter-actionIcon {
    margin-right: 10px;
}

.mobile .sidebar,
.tablet .sidebar {
    grid-template-rows: max-content auto max-content;
    position: fixed;
    z-index: 5;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100vh;
    height: var(--appHeight);
    width: 100vw;
    font-size: 17px;
}

.mobile .sidebar-isHidden,
.tablet .sidebar-isHidden {
    opacity: 0;
    pointer-events: none;
    /* transition: var(--fadeTransition); */
    /* filter: blur(20px); */
}

.mobile .sidebar .sidebarFooter-actions,
.tablet .sidebar .sidebarFooter-actions {
    justify-content: flex-end;
}

.mobile .sidebar .sidebarFooter-actions > *,
.tablet .sidebar .sidebarFooter-actions > * {
    margin-right: 20px;
}

.tablet .sidebar .sidebarFooter-actions {
    justify-content: center;
}

.tablet .sidebar .sidebarItemList {
    width: 35%;
}

.mobile .sidebar .sidebarItem {
    padding: 20px 30px 20px 20px;
}

.settings-action {
    background: white;
    display: flex;
    justify-content: flex-start;
    padding: 8px 15px;
    color: var(--blue);
    border-radius: 20px;
    margin: 0 20px;
    width: fit-content;
}

.mobile .settings-action,
.tablet .settings-action {
    padding: 8px 15px !important;
    background: transparent;
    color: white;
}

.settings-action img {
    width: 26px;
    height: 26px;
}

.settingsPane {
    width: 175px;
    position: fixed;
    left: 70px;
    top: 0;
    bottom: 0;
    background-color: #fbfbfb;
    padding-top: 50px;
    z-index: 2;
}

.mobile .settingsPane,
.tablet .settingsPane {
    width: 100vw;
    height: calc(100vh - 64px);
    position: fixed;
    left: 0;
    top: 64px;
    bottom: 0;
    padding: 33px;
    z-index: 5;
}

.settingsPane-isHidden {
    display: none;
}

.settingsPaneTitle {
    padding: 10px;
    font-size: 18px;
}

.mobile .settingsPaneTitle,
.tablet .settingsPaneTitle {
    padding: 10px 0;
    font-size: 18px;
}

.settingsPaneItemList {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
}

.settingsPaneItem {
    padding: 10px 0 10px 25px;
    font-size: 13px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #3b3b3b;
}

.mobile .settingsPaneItem,
.tablet .settingsPaneItem {
    padding: 10px 0 10px 0;
    border-bottom: 0.5px solid #0000000f;
}

.settingsPaneItem img {
    width: 8px;
    height: 13px;
}

.settingsPaneItem.settingsPaneItem-isOpen,
.settingsPaneItem:hover {
    color: white;
    background: var(--blueGradient);
}

.mobile .settingsPaneItem.settingsPaneItem-isOpen,
.tablet .settingsPaneItem.settingsPaneItem-isOpen,
.mobile .settingsPaneItem:hover,
.tablet .settingsPaneItem:hover {
    background: transparent;
    color: #3b3b3b;
}
