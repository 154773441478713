.accountActions {}
.accountActions-details {
  margin: 0 15px;
}
.accountNotification {
  position: relative;
  padding: 0 10px;
}
.accountNotification-hasBadge::after {
  content: '';
  width: 8px;
  height: 8px;
  border-radius: 8px;
  position: absolute;
  top: 0;
  right: 0;
  background: var(--blueGradient);
}
.accountActions--inverted {
  flex-direction: row-reverse !important;
}
.accountActions--inverted * {
  color: white !important;
}
.accountActions--inverted .accountNotification-hasBadge::after {
  background: var(--yellowGradient);
}
/* .accountActions-divider {
  display: block;
  border-top: 1px solid var(--grey200);
  opacity: .5;
  margin: 10px 0;
} */
.accountActions-collapse {
  padding: 10px 0;
}
.accountActions-collapseItem {
  padding: 15px 10px;
}
.accountActions-collapseItem > img {
  width: 18px;
  margin-right: 20px;
}
.accountActionsPopover-icon {
  width: 15px;
  margin-right: 10px;
}
.accountActions-fullname {
  font-size: 1rem;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}