
.announcements {}
.mobile .announcements {
  display: grid;
  grid-template-rows: auto;
  grid-row-gap: 10px;
  padding-bottom: 30px;
}
.desktop .announcements .announcements-head,
.tablet .announcements .announcements-head {
  margin-bottom: 5px;
}
.announcements-tableBody {
    overflow: auto;
  max-height: 260px !important;
}
.announcements-tableRow,
.announcements-mobileCard {
  position: relative;
}
.announcements-tableRow {
  height: auto !important;
  min-height: 20px;
  border-bottom: 1px solid var(--grey300);
  padding: 10px 20px 10px 20px;
}
.announcements-tableRow:last-child {
  border-bottom: none;
}
.announcements-mobileCard {
  padding-left: 30px !important;
}
.announcements-tableRow-hasNotification::after,
.announcements-mobileCard-hasNotification::after {
  height: 8px;
  width: 8px;
  border-radius: 8px;
  background: var(--blueGradient);
  content: "";
  position: absolute;
  top: 22px;
}
.announcements-tableRow-hasNotification::after {
  left: 0;
}
.announcements-mobileCard-hasNotification::after {
  left: 10px;
}