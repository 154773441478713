.StudentList {
    display: grid;
    grid-gap: 15px;
}
.mobile .StudentListItem {
    position: relative;
}
.mobile .StudentListItem > *+*:not(.StudentListItem-checkbox):not(.StudentListItem-avi) {
    margin-top: 10px;
}
.mobile .StudentListItem-checkbox {
    position: absolute;
    right: 15px;
}
.mobile .StudentListItem-avi {
    float: left;
    margin-right: 15px;
    margin-bottom: 15px;
}