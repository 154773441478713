.absentStudents {
    display: grid;
    grid-template-columns: 1fr 2.5fr;
    grid-column-gap: 20px;
    grid-template-rows: auto;
    grid-row-gap: 5px;
  }
  .absentStudents-head {
    grid-column: 1 / span 2;
  }
  .absentStudents-summaryCard {
    min-height: 200px;
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-row-gap: 20px;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
    align-items: center;
  }
  .absentStudents-mobileCard {
    display: grid;
    grid-template-columns: 1fr max-content;
    align-items: center;
    line-height: 2;
  }
  .absentStudents-mobileCard > div:last-child {
    border-left: 1px solid var(--grey200);
    text-align: center;
    padding: 0 30px;
  }
  .absentStudents-tableRow {
    grid-template-columns: repeat(4, 1fr);
  }
  .tablet .absentStudents,
  .mobile .absentStudents {
    grid-template-columns: unset;
    grid-template-rows: auto;
    grid-row-gap: 10px;
  }
  .mobile .absentStudents {
    padding-bottom: 30px;
  }
  .tablet .absentStudents .absentStudents-head,
  .mobile .absentStudents .absentStudents-head {
    grid-column: unset;
  }
  .tablet .absentStudents .absentStudents-summaryCard,
  .mobile .absentStudents .absentStudents-summaryCard {
    display: flex;
    min-height: unset;
  }
  .tablet .absentStudents .absentStudents-summaryCard {
    justify-content: center;
  }
  .tablet .absentStudents .absentStudents-summaryCard > * {
    padding: 0 5px;
  }
  .tablet .absentStudents .absentStudents-summaryCard > *:nth-child(odd) {
    padding-bottom: 20px;
  }
  .mobile .absentStudents .absentStudents-summaryCard {
    justify-content: space-between;
  }