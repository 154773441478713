.EmptyList {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px 0;
    color: var(--grey);
    text-transform: uppercase;
    letter-spacing: 1px;
}
.EmptyList .material-icons {
  font-size: 8rem;
  color: var(--grey300);
}