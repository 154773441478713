/* @page {
    size: A4;
    margin: 0;
  } */
/* html,body{
    

} */

.pdf {
    background: #fff;
    height:297mm;
    width:210mm;
    margin: 0 auto
}

.pdf-header{
    display: grid;
    grid-template-columns: minmax(30mm, max-content) repeat(3, minmax(5rem, 1fr));
    grid-column-gap: 5px;
    padding-top: 25px;
}


.pos-header-logo{
    
}

.pos-header div:nth-child(2) > * {
    margin-bottom: 8px;
}
.pos-header-logo {
    justify-self: center;
}
.pos-cardHeader{
    padding: 15px;
    font-size: 10px;
}
.pos-card{
    border: 1.5px dashed rgba(189, 189, 189, 0.25);
    margin: 12px;
    padding-bottom: 0;
}
.pos-walletTransactions{
    background: rgba(238, 245, 250, 0.43);
}
.FeeTransactionsItem>*>*>*{
    margin-bottom: 5px;
}
.FeeTransactionsItem>*>* {
    margin-bottom: 1rem;
}


tfoot td,
tfoot th {

    text-align: center;
    padding: 1.3rem;
    width: 20%;
    border-bottom: 1px solid #dddddd;
}

tfoot {
    text-transform: uppercase;
}

tfoot .td-wallet:first-child {
    border-right: 1px solid #dddddd;
    font-size: 1.2rem;
}


/* */