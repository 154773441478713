.schoolSwap {
  height: var(--schoolSwap-height);
  background: white;
  padding: 0 10px;
  display: flex;
  align-items: center;
}
.schoolSwap-avatar {
  height: 40px;
  width: 40px;
  border-radius: 40px;
  border: 1px solid #fad961;
  margin-right: 10px;
  background: var(--grey200);
}
.schoolSwap-avatar img {
  height: inherit;
  width: inherit;
}
.schoolSwap-title {
  font-size: 12px;
  font-weight: 700;
  margin-right: 20px;
}