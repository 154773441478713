.ReportCard {}
.ReportCard-head {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.ReportCard-avatar {
    grid-row: span 2;
    margin-right: 20px;
}
.ReportCard-profile {
    display: grid;
    grid-column-gap: 30px;
    grid-row-gap: 20px;
    grid-template-columns: repeat(4, max-content);
}
.ReportCard-profile > *:first-child {
    grid-column: span 4;
}
.ReportCard--mobile .ReportCard-profile {
    grid-template-columns: repeat(2, max-content);
}
.ReportCard--mobile .ReportCard-profile > *:first-child {
    grid-column: span 2;
}
.ReportCard-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 30px;
    grid-row-gap: 15px;
    padding: 15px 0;
    align-items: flex-start;
}
.ReportCard-subjects {
    grid-column: span 2;
    overflow: auto;
}
.ReportCard-affective {
    grid-row: span 3;
}
.ReportCard-subjects,
.ReportCard-summary,
.ReportCard-attendance,
.ReportCard-affective,
.ReportCard-comment {
    border-top: 1px solid var(--grey100);
    border-bottom: 1px solid var(--grey100);
}
.ReportCard-comment {
    grid-column: span 2;
}

.mobile .ReportCard-affective, .mobile  .ReportCard-summary, .mobile .ReportCard-attendance{
    grid-column: span 2;
}


/* navigator Styling */
.navigatableSelectWrapper{
    max-width: 700px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tablet .navigatableSelectWrapper, .desktop .navigatableSelectWrapper {
    min-width: 500px;
}

.mobile .navigatableSelectWrapper {
    min-width: calc(100vw - 120px);
}

.navigatableSelect .text-p{
    border: none;
    box-shadow: none;

    font-size: 18px !important;
    font-weight: 600;
    line-height: 1.75rem;
    
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}