.LogIn {
    background: var(--blueGradient);
    min-height: 100vh;
    padding: 10px 0;
}

.LogIn-card {
    padding: 30px !important;
    padding-top: 55px !important;
    margin-top: 55px;
    width: 300px;
    min-height: 380px;
}

.mobile .LogIn-card {
    max-width: calc(100vw - 80px);
    padding-bottom: 15px !important;
}

.LogIn-card>* {
    margin-bottom: 15px;
}

.LogIn-poweredBy {
    padding-top: 10px;
    color: white;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
}

.LogIn-schoolLogo {
    width: 100px;
    height: 100px;
    border-radius: 100px;
    background: var(--blueGradient);
    padding: 2px;
    position: absolute;
    margin-top: -110px;
    margin-bottom: 0;
    align-self: center;
}

.LogIn-schoolLogoContent {
    width: stretch;
    height: stretch;
    background: white;
    overflow: hidden;
    border-radius: inherit;
    padding: 10px;
    box-sizing: unset;
}

.LogIn-schoolLogoContent img {
    width: 100%;
    height: 100%;
}