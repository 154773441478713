.FeeTransactionSearch-TabletForm {
    display: grid;
    grid-template-columns: 2fr 2fr repeat(2, max-content);
    align-items: center;
    grid-column-gap: 15px;
    margin-bottom: -10px;
}
.FeeTransactionSearch-DesktopForm {
    display: grid;
    grid-template-columns: 1.5fr 1fr 1fr repeat(2, max-content);
    align-items: center;
    grid-column-gap: 15px;
}
.FeeTransactionSearch-DesktopForm > .FeeTransactionSearch-MoreFields {
    grid-column: span 5;
    display: grid;
    grid-column-gap: 15px;
    grid-template-columns: repeat(4, minmax(100px, auto));
    grid-template-rows: auto;
    margin-bottom: -10px;
}