.Settings .Classes {
    width: 100%;
    padding-top: 10px;
}

.mobile .Settings .Classes,
.tablet .Settings .Classes {
    padding: 29px 20px;
}

.Settings .Classes--Body {
    width: 100%;
    height: calc(100vh - 300px);
}

.Settings .Classes--Header {
    width: 100%;
}

.Settings .Classes--Header h2 {
    color: #6d6d6d;
}

.Settings .Classes--cta-wrapper {
    display: flex;
    margin-bottom: 50px;
    margin-top: 26px;
}

.mobile .Settings .Classes--cta-wrapper,
.tablet .Settings .Classes--cta-wrapper {
    justify-content: center;
    margin-bottom: 30px;
    margin-top: 26px;
}

.Settings .Classes--cta-wrapper button:first-child {
    margin-right: 20px;
}

.mobile .Settings .Classes--cta-wrapper button:first-child,
.tablet .Settings .Classes--cta-wrapper button:first-child {
    margin-right: 0px;
}

.Settings .Classes--cta-wrapper button {
    padding: 0 24px;
}

.mobile .Settings .Classes--cta-wrapper button,
.tablet .Settings .Classes--cta-wrapper button {
    width: 100%;
}

.Settings .Classes--cta-table-wrapper th,
.Settings .Classes--cta-table-wrapper td {
    text-align: center;
}

.Settings .Classes--cta-table-wrapper .Table th,
.Settings .Classes--cta-table-wrapper .Table td {
    padding: 30px;
}

.Settings .Classes--cta-table-wrapper .Table th:nth-child(2),
.Settings .Classes--cta-table-wrapper .Table td:nth-child(2) {
    text-align: left;
    padding-left: 60px;
}

.Settings .Classes--cta-table-wrapper .Table .ant-switch {
    background-color: #F6F6F6 !important;
}

.Settings .Classes--cta-table-wrapper .Table .ant-switch::after {
    background-color: #FFF !important;
}

.Settings .Classes--cta-table-wrapper .Table .ant-switch-checked {
    background-color: #c3c6f7 !important;
    border-color: #c3c6f7 !important;
}

.Settings .Classes-MobileRow {
    position: relative;
}

.Settings .Classes-MobileRow--cta {
    position: absolute;
    right: 0px;
    top: -8px;
}

.Settings .Classes-MobileRow--Body {
    display: flex;
    flex-direction: column;
    padding: 20px 10px 10px;
}

.Settings .Classes-MobileRow--Body--top {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 20px;
}

.Settings .Classes-MobileRow--Row span:first-child {
    margin-right: 20px;
}


.Settings .Classes-MobileRow .ant-switch {
    height: 25px !important;
    width: 46px !important;
}

.Settings .Classes-MobileRow .ant-switch::after {
    height: 20px !important;
    width: 20px !important;
}

.Settings .ant-switch::after {
    content: "" !important;
}

.Settings .ant-switch-checked::after {
    content: "" !important;
}
