.utils-prepend {
  margin-right: 10px;
}
.utils-prepend--large {
  margin-right: 15px;
}
.utils-prepend--xlarge {
  margin-right: 30px;
}
.utils-append {
  margin-left: 10px;
}
.utils-append--large {
  margin-left: 15px;
}
.utils-padded {
  padding: 30px;
}
.utils-padded-small {
  padding: 20px;
}
.utils-center {
    margin: 0 auto;
}
.utils-fullwidth {
  width: 100% !important;
  width: stretch !important;
}
.utils-halfwidth {
  width: 50%;
}
.utils-fullheight {
  height: 100%;
}
.utils-hide {
  display: none !important;
}
.utils-padTop {
  padding-top: 15px !important;
}
.utils-padTop-small {
  padding-top: 10px;
}
.utils-padBottom {
  padding-bottom: 15px !important;
}
.utils-padLeft {
  padding-left: 15px !important;
}
.utils-noPadLeft {
  padding-left: 0;
}
.utils-padRight {
  padding-right: 15px !important;
}
.utils-maxContentWidth {
  width: max-content;
}
.mb-1 {
  margin-bottom: 1rem;
}
.mb-2 {
  margin-bottom: 2rem;
}
.mb-3 {
  margin-bottom: 3rem;
}
.mb-5 {
  margin-bottom: 5rem;
}
.mt-1 {
  margin-top: 1rem;
}
.mt-2 {
  margin-top: 2rem;
}
.mt-3 {
  margin-top: 3rem;
}
.mr-1 {
  margin-right: 1rem;
}
.mr-2 {
  margin-right: 2rem;
}
.mr-3 {
  margin-right: 3rem;
}
.ml-2{
  margin-left: 2rem;
}
.my-1 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}
.pb-1 {
  padding-bottom: 1rem;
}
.pb-2 {
  padding-bottom: 2rem;
}
.pb-3 {
  padding-bottom: 3rem;
}
.pl-1 {
  padding-left: 1rem;
}
.pl-2 {
  padding-left: 2rem;
}
.pR-1 {
  padding-right: 1rem;
}
.pt-1 {
  padding-top: 1rem;
}
.pt-2 {
  padding-top: 2rem;
}
.utils-logo{
  width: 65px;
  height: 68px;
}
.inline-block{
    display: inline-block;
}

.rotate180 {
    -webkit-transform:rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}