.CreateGuardian-form {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 15px;
}
.tablet .CreateGuardian-form {
    grid-template-columns: repeat(2, 1fr);
}
.mobile .CreateGuardian-form {
    display: block;
}
.desktop .ward-Details,
.tablet .ward-Details {
    display: grid;
    grid-template-columns: repeat(2,minmax(50%, 1fr));
    grid-column-gap: 15px;
}
.desktop .CreateGuardian-wardDetails,
.tablet .CreateGuardian-wardDetails {
    width: 50%;
}
.Avatar.ward-input-image {
    width: 45px;
    height: 45px;
    position: absolute;
    z-index: 5;
    left: 0;
    top: 0;
}

.wardName-input--text {
    padding-left: 50px;
}