.UserBioData {
    display: grid;
    grid-template-columns: 1.2fr 1fr;
    grid-template-rows: max-content;
    grid-gap: 10px;
}
.desktop .UserBioData > *:first-child,
.tablet .UserBioData > *:first-child {
    grid-row: span 4;
}
.tablet .UserBioData {
    grid-template-columns: repeat(2, 1fr);
}
.mobile .UserBioData {
    grid-template-columns: unset;
}
.UserBioData > *:first-child {
    grid-row: span 2;
}