.SubjectScoreSheet-grid {
    overflow-x: auto;
    max-height: 500px;
    position: relative;
    display: flex;
}

.mobile .SubjectScoreSheet-grid {
    max-height: 80vh;
}

.SubjectScoreSheet-col > *:first-child {
    position: sticky;
    top: 0;
    background: white;
    z-index: 2;
    font-size: 10px;
    font-weight: 600;
    /*justify-content: flex-start;*/
}

.SubjectScoreSheet-cell:last-child {
    border-bottom: none;
}

.SubjectScoreSheet-cell:nth-child(even) {
    background: var(--grey100);
}

.SubjectScoreSheet-cell {
    height: 50px;
    border-bottom: 1px solid var(--grey200);
    /*border-right: 1px solid var(--grey200);*/
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    text-transform: uppercase;
}

.SubjectScoreSheet-leftPane {
    width: max-content;
    white-space: nowrap;
}

.SubjectScoreSheet-leftPane .SubjectScoreSheet-cell {
    justify-content: flex-start;
}

.SubjectScoreSheet-col,
.SubjectScoreSheet-col > * {
    transition: background .4s ease-in-out;
    border: 1px solid var(--grey200);
    border-left: 0;
}

.SubjectScoreSheet-col input{
    width: 40px;
    z-index: 2;
}

.SubjectScoreSheet-col:first-child {
    position: sticky;
    left: 0;
    z-index: 3;
    background: white;
    border-left: 1px solid var(--grey200);
}

.SubjectScoreSheet-col {
    height: 50%;
    position: relative;
}

.SubjectScoreSheet-col:not(:first-child):hover::after {
    background: var(--warningColor100) !important;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    pointer-events: none;
}

.SubjectScoreSheetToolBar {
    display: grid;
    grid-gap: 15px;
    align-items: center;
    grid-template-columns: max-content auto max-content;
    margin-bottom: 30px;
}

.SubjectScoreSheetToolBar>.termSelectLabel {
    line-height: 16px;
    font-size: 12px;
    margin-bottom: 0;
}

.mobile .SubjectScoreSheetToolBar {
    grid-template-columns: auto;
}

.mobile .SubjectScoreSheetToolBar>button {
    position: absolute;
    top: 1px;
    width: max-content;
    right: 16px;
}


.NameColumnHeader {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: 95px 26px 26px;
    border: 1px solid var(--grey200);
    border-right: 0;
}

.NameColumnHeader>div.nameLabel {
    justify-self: start;
    align-self: end;
    grid-row: 1 / 4;
    padding: 16px 21px;
}

.NameColumnHeader>div:not(.nameLabel) {
    line-height: 16px;
    padding: 3px 7px;
    text-transform: capitalize;
    font-weight: 400;
    font-size: 10px;
    border: solid var(--grey200);
    border-width: 1px 0 0 1px;
}

.AssessmentColumnHeader {
    display: grid;
    grid-template-rows: 95px 26px 26px;
    white-space: nowrap;
}

.AssessmentColumnHeader>div {
    line-height: 16px;
    padding: 3px 7px;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 10px;
    text-align: center;
}

.AssessmentColumnHeader>div.first>div {
    text-align: start;
    transform: rotate(270deg) translate(-37px, 0px);
    display: inline-block;
    position: relative;
}

.AssessmentColumnHeader>div.first>div>span {
    display: block;
    font-weight: bold;
}

.AssessmentColumnHeader>div.first>button {
    position: absolute;
    right: 10px;
    top: 10px;
    padding: 0;
}

.AssessmentColumnHeader>div:not(.first) {
    border-top: 1px solid var(--grey200);
    color: var(--blue);
    font-weight: bold;
}