.AssignGuardian-directSearch {
    /* transition: max-height .5s ease;
    max-height: 10000px; */
    /* overflow: hidden; */
}
.AssignGuardian-directSearch > *+* {
    margin-top: 10px;
}
.AssignGuardian-directSearch-closed {
    /* max-height: 0; */
}
.AssignGuardianItem {
    display: grid;
    grid-template-columns: 45px repeat(2,1fr);
    grid-template-rows: auto;
    grid-gap: 10px;
    grid-template-areas:
        "avatar     name            name"
        "avatar     mobile_phone    mobile_phone"
        ".          phone           phone"
        "email      email           button";
    margin-bottom: 10px;
}
.AssignGuardianItem-photo {
    grid-area: avatar;
}
.AssignGuardianItem-name {
    grid-area: name;
}
.AssignGuardianItem-mobile_phone {
    grid-area: mobile_phone;
}
.AssignGuardianItem-phone {
    grid-area: phone;
}
.AssignGuardianItem-email {
    grid-area: email;
}
.AssignGuardianItem-actions {
    grid-area: button;
    justify-self: flex-end;
}